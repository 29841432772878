import pageNotFound from '../assets/images/page-not-found.webp';
import React from 'react'
import Navbar from '../component/navbar'
import ContactData from '../component/contact-data';
import Footer from '../component/footer';
import {useTranslation} from "react-i18next";

export default function PageNotFound() {
    const [t] = useTranslation('common');

    return (
        <>
            <Navbar/>
            <section
                className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-contact.webp')] bg-top bg-cover bg-no-repeat"
                id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">{t('notFound.title')}</h3>
                    </div>
                </div>
            </section>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5 md:col-span-6">
                            <img src={pageNotFound} alt={t('general.accessibility.alt.contact')} className="w-fit h-auto"/>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md p-6">
                                    <h4 className="mb-6 text-2xl leading-normal font-medium">{t('notFound.subtitle')}</h4>
                                    <p className="mb-6 text-lg leading-normal font-medium">{t('notFound.text')}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactData/>
            </section>
            <Footer/>
        </>
    )
}
