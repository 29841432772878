import header from '../../assets/images/services/consulting/student.webp';
import online from '../../assets/images/services/consulting/online-training.webp';
import inPerson from '../../assets/images/services/consulting/in-person-training.webp';
import React from 'react'
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Option from "../../component/web-development/option";

import RelatedServices from "../../component/related-services";

export default function Training() {
    const [t] = useTranslation('common');

    const services = [
        {
            icon: 'lightbulb-alt',
            title: t('about.services.consulting.title'),
            description: t('about.services.consulting.text'),
            link: "/services/consulting"
        },
        {
            icon: 'graduation-cap',
            title: t('about.services.training.title'),
            description: t('about.services.training.text'),
            link: "/services/training"
        },
        {
            icon: 'document-info',
            title: t('about.services.audit.title'),
            description: t('about.services.audit.text'),
            link: "/services/audit"
        }
    ];

    return (
        <>
            <link rel="preload" as="image" href={header}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 py-0 md:h-auto flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {t('services.pages.training.header')} <span
                                    className="font-bold">{t('services.pages.training.headerStrong')}</span>
                                    {t('services.pages.training.headerAfter')}
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.training.text')}</p>
                            </div>

                            <img src={header} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto mx-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <Option title={t('services.pages.training.services.online.title')}
                    image={online}
                    imageAlt={t('services.pages.training.services.online.imageAlt')}
                    textLines={[
                        t('services.pages.training.services.online.text.1'),
                        t('services.pages.training.services.online.text.2'),
                        t('services.pages.training.services.online.text.3'),
                        t('services.pages.training.services.online.text.4')
                    ]}
                    orientation={"right"}
                    textClassName="dark:text-slate-100"
            />
            <Option title={t('services.pages.training.services.inPerson.title')}
                    image={inPerson}
                    imageAlt={t('services.pages.training.services.inPerson.imageAlt')}
                    textLines={[
                        t('services.pages.training.services.inPerson.text.1'),
                        t('services.pages.training.services.inPerson.text.2'),
                        t('services.pages.training.services.inPerson.text.3')
                    ]}
                    orientation={"left"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <RelatedServices services={services} subtitle={t('services.relatedServices.subtitles.infrastructure')}/>
            <GetInTouch/>
            <Footer/>
        </>
    )
}
