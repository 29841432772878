import React from 'react';
import {Link} from "react-router-dom";
import {Hexagon} from 'react-feather';
import {useTranslation} from "react-i18next";

export default function RelatedServices({services, subtitle, isLight}) {
    const [t] = useTranslation('common');

    return (
        <section
            className={`relative md:py-8 py-8 ${isLight ? 'bg-white dark:bg-slate-900' : 'bg-gray-50 dark:bg-slate-800'}`}
            id="features">
            <div className="container lg mx-auto">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('services.relatedServices.title')}</h3>
                    <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">
                        {subtitle}
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    {services.map((service, index) => {
                        return (
                            <div key={index}
                                 className={`group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center`}>
                                <div className="relative overflow-hidden text-transparent -m-3">
                                    <Hexagon className="h-28 w-28 fill-brandColor-600/5 mx-auto rotate-[30deg]"
                                    ></Hexagon>
                                    <div
                                        className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-brandColor-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                        <i className={`uil uil-${service.icon}`}></i>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <Link to={service.link}
                                          className="text-lg h5 transition duration-500 ease-in-out hover:text-brandColor-600">
                                        {service.title}
                                    </Link>
                                    <p className="text-slate-500 dark:text-slate-300 transition duration-500 ease-in-out mt-3">
                                        {service.description}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
};
