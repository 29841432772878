import header from '../../assets/images/services/web-development/mobile-development.webp';
import mobileApps from '../../assets/images/services/web-development/mobile-apps.webp';
import React from 'react'
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Option from "../../component/web-development/option";
import Technologies from "../../component/technologies";

import android from '../../assets/images/services/web-development/android.svg';
import dotnet from '../../assets/images/services/web-development/dotnet.svg';
import kotlin from '../../assets/images/services/web-development/kotlin.svg';
import javascript from '../../assets/images/services/web-development/javascript.svg';
import ios from '../../assets/images/services/web-development/ios.webp';
import python from '../../assets/images/services/web-development/python.svg';
import react from '../../assets/images/services/web-development/react.svg';
import swift from '../../assets/images/services/web-development/swift.svg';
import typescript from '../../assets/images/services/web-development/typescript.svg';
import mongodb from '../../assets/images/services/web-development/mongodb.svg';
import mysql from '../../assets/images/services/web-development/mysql.svg';
import postgresql from '../../assets/images/services/web-development/postgresql.svg';
import RelatedServices from "../../component/related-services";

const technologies = [
    {
        name: 'React',
        image: react
    },
    {
        name: 'Kotlin',
        image: kotlin
    },
    {
        name: 'Swift',
        image: swift
    },
    {
        name: 'Android',
        image: android
    },
    {
        name: 'iOS',
        image: ios
    },
    {
        name: 'JavaScript',
        image: javascript
    },
    {
        name: 'Python',
        image: python
    },
    {
        name: 'TypeScript',
        image: typescript
    },
    {
        name: '.NET',
        image: dotnet
    },
    {
        name: 'MongoDB',
        image: mongodb
    },
    {
        name: 'MySQL',
        image: mysql
    },
    {
        name: 'PostgreSQL',
        image: postgresql
    },
];

export default function MobileDevelopment() {
    const [t] = useTranslation('common');

    const services = [
        {
            icon: 'monitor',
            title: t('about.services.webDevelopment.title'),
            description: t('about.services.webDevelopment.text'),
            link: "/services/web-applications"
        },
        {
            icon: 'robot',
            title: t('about.services.ai.title'),
            description: t('about.services.ai.text'),
            link: "/services/artificial-intelligence"
        },
        {
            icon: 'server-network-alt',
            title: t('about.services.infrastructure.title'),
            description: t('about.services.infrastructure.text'),
            link: "/services/infrastructure-management"
        }
    ];

    return (
        <>
            <link rel="preload" as="image" href={header}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 py-0 md:h-auto flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {t('services.pages.mobileDevelopment.header')} <span
                                    className="font-bold">{t('services.pages.mobileDevelopment.headerStrong')}</span>
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.mobileDevelopment.text')}</p>
                            </div>

                            <img src={header} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <Technologies technologies={technologies}/>
            <Option title={t('services.pages.mobileDevelopment.services.mobile.title')}
                    image={mobileApps}
                    imageAlt={t('services.pages.mobileDevelopment.services.mobile.imageAlt')}
                    textLines={[
                        t('services.pages.mobileDevelopment.services.mobile.text.1'),
                        t('services.pages.mobileDevelopment.services.mobile.text.2'),
                        t('services.pages.mobileDevelopment.services.mobile.text.3'),
                        t('services.pages.mobileDevelopment.services.mobile.text.4'),
                        t('services.pages.mobileDevelopment.services.mobile.text.5')
                    ]}
                    orientation={"right"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <RelatedServices services={services} isLight subtitle={t('services.relatedServices.subtitles.generic')}/>
            <GetInTouch/>
            <Footer/>
        </>
    )
}
