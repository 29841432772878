import laptop from '../../assets/images/laptop3.webp';
import React from 'react'
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import {Link} from "react-router-dom";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Signalist() {
    const [t] = useTranslation('common');

    return (
        <>
            <link rel="preload" as="image" href={laptop}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 md:h-auto py-0 flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-light mb-7 position-relative dark:text-white">
                                    {t('services.pages.signalist.header')} <span
                                    className="font-bold">{t('services.pages.signalist.headerStrong')}</span>
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.signalist.text')}</p>

                                <div className="relative mt-10">
                                    <Link to="/contactus"
                                          className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:border-brandColor-800 text-white rounded-md">
                                        {t('services.pages.signalist.trial')}
                                    </Link>
                                </div>
                            </div>

                            <img src={laptop} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-12 py-8" id="pricing">
                <div className="container">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('services.pages.signalist.demo.title')}</h3>
                        <p className="text-slate-400 dark:text-slate-300 max-w-4xl mx-auto mb-12">{t('services.pages.signalist.demo.subtext')}</p>
                        <div>
                            <h4 className="md:text-lg font-medium mb-1">{t('services.pages.signalist.demo.address')}</h4>
                            <a href={"https://demo.signalist.pl"}><span
                                className="text-xl text-brandColor-600 hover:text-brandColor-700 dark:text-brandColor-500 dark:hover:text-brandColor-600">https://demo.signalist.pl</span></a>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 mb-12 mt-4 gap-12">
                            <div className="flex flex-col gap-1">
                                <h4 className="md:text-lg font-medium mb-1">{t('services.pages.signalist.demo.admin.title')}</h4>
                                <span
                                    className="font-lighter text-slate-400 dark:text-slate-300 mb-4">{t('services.pages.signalist.demo.admin.instruction')}</span>
                                <span><strong
                                    className="font-medium">{t('services.pages.signalist.demo.username')}: </strong>admin@retoric.pl</span>
                                <span className="mb-4"><strong
                                    className="font-medium">{t('services.pages.signalist.demo.password')}: </strong>default</span>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h4 className="md:text-lg font-medium mb-1">{t('services.pages.signalist.demo.user.title')}</h4>
                                <span
                                    className="font-lighter text-slate-400 dark:text-slate-300 mb-4">{t('services.pages.signalist.demo.user.instruction')}</span>
                                <span><strong
                                    className="font-medium">{t('services.pages.signalist.demo.password')}: </strong>demo123</span>
                            </div>
                        </div>
                        <p className="text-slate-400 dark:text-slate-300 max-w-4xl mx-auto mb-12">{t('services.pages.signalist.demo.contactUs')}</p>
                    </div>
                </div>
            </section>
            <GetInTouch signalist/>
            <Footer/>
        </>
    )
}
