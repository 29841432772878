import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";

export default function IntranetFunctions() {
    const [activeQuestion, setActiveQuestion] = useState(-1);
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});

    const [t] = useTranslation('common');

    useEffect(() => {
        function onMouseMove(e) {
            setMousePosition({
                x: e.x,
                y: e.y
            })
        }

        function onScroll(e) {
            setActiveQuestion(-1);
        }

        window.addEventListener('mousemove', onMouseMove)
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('mousemove', onMouseMove)
            window.removeEventListener('scroll', onScroll);
        }
    }, []);

    const features = [
        {
            name: t('services.pages.intranet.functions.search.title'),
            description: t('services.pages.intranet.functions.search.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.news.title'),
            description: t('services.pages.intranet.functions.news.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.calendar.title'),
            description: t('services.pages.intranet.functions.calendar.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.tasks.title'),
            description: t('services.pages.intranet.functions.tasks.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.info.title'),
            description: t('services.pages.intranet.functions.info.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.quickLinks.title'),
            description: t('services.pages.intranet.functions.quickLinks.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.guides.title'),
            description: t('services.pages.intranet.functions.guides.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.software.title'),
            description: t('services.pages.intranet.functions.software.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.holiday.title'),
            description: t('services.pages.intranet.functions.holiday.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.signalist.title'),
            description: t('services.pages.intranet.functions.signalist.description'),
            team: true,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.integrations.title'),
            description: t('services.pages.intranet.functions.integrations.description'),
            team: false,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.kanban.title'),
            description: t('services.pages.intranet.functions.kanban.description'),
            team: false,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.delegations.title'),
            description: t('services.pages.intranet.functions.delegations.description'),
            team: false,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.aiassistant.title'),
            description: t('services.pages.intranet.functions.aiassistant.description'),
            team: false,
            business: true,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.orders.title'),
            description: t('services.pages.intranet.functions.orders.description'),
            team: false,
            business: false,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.geotool.title'),
            description: t('services.pages.intranet.functions.geotool.description'),
            team: false,
            business: false,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.customisation.title'),
            description: t('services.pages.intranet.functions.customisation.description'),
            team: false,
            business: false,
            enterprise: true,
        },
        {
            name: t('services.pages.intranet.functions.hosting.title'),
            description: t('services.pages.intranet.functions.hosting.description'),
            team: false,
            business: false,
            enterprise: true,
            hidden: true
        }
    ]

    return (
        <>
            <section className="relative md:py-12 py-8" id="pricing">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('services.pages.intranet.functions.title')}</h3>

                        <p className="text-slate-400 dark:text-slate-300 max-w-4xl mx-auto">{t('services.pages.intranet.functions.subtext')}</p>
                    </div>

                    <div className="mb-8">
                        <div className="grid grid-cols-6 lg:grid-cols-4 lg:hidden flex-1">
                            <div className="lg:p-4 p-1  text-left border-r-2 col-span-3 lg:col-span-1">
                                {t('services.pages.intranet.functions.feature')}
                            </div>
                            <div className="lg:p-4 text-center border-r-2 font-bold">
                                {t('pricing.options.team.title')[0]}.
                            </div>
                            <div className="lg:p-4 text-center border-r-2 font-bold">
                                {t('pricing.options.business.title')[0]}.
                            </div>
                            <div className="lg:p-4 text-center font-bold">
                                {t('pricing.options.enterprise.title')[0]}.
                            </div>
                        </div>
                        <div className="grid-cols-4 hidden lg:grid">
                            <div className="lg:p-4 text-left border-r-2">
                                {t('services.pages.intranet.functions.feature')}
                            </div>
                            <div className="lg:p-4 text-center border-r-2 font-bold">
                                {t('pricing.options.team.title')}
                            </div>
                            <div className="lg:p-4 text-center border-r-2 font-bold">
                                {t('pricing.options.business.title')}
                            </div>
                            <div className="lg:p-4 text-center font-bold">
                                {t('pricing.options.enterprise.title')}
                            </div>
                        </div>
                        {
                            features.filter(x => !x.hidden).map((feature, index) => (
                                <div className="grid grid-cols-6 lg:grid-cols-4" key={index}>
                                    <div
                                        className="lg:p-3 p-1  text-left border-r-2  border-t-2 font-light col-span-3 lg:col-span-1">
                                        {feature.name} <i
                                        className="uil uil-question-circle text-lg text-blue-700 dark:text-blue-200 hover:text-brandColor-500 dark:hover:text-brandColor-500"
                                        onMouseOver={() => setActiveQuestion(index)}
                                        onMouseOut={() => setActiveQuestion(-1)}/>
                                    </div>
                                    <div className="text-center border-r-2  border-t-2 lg:p-1">
                                        {feature.team ?
                                            <i className="uil uil-check text-4xl text-green-600 dark:text-green-500"/> :
                                            <i className="uil uil-times text-4xl text-red-600 dark:text-red-500"/>}
                                    </div>
                                    <div className="text-center border-r-2  border-t-2 lg:p-1">
                                        {feature.business ?
                                            <i className="uil uil-check text-4xl text-green-600 dark:text-green-500"/> :
                                            <i className="uil uil-times text-4xl text-red-600 dark:text-red-500"/>}
                                    </div>
                                    <div className="text-center border-t-2 lg:p-1">
                                        {feature.enterprise ?
                                            <i className="uil uil-check text-4xl text-green-600 dark:text-green-500"/> :
                                            <i className="uil uil-times text-4xl text-red-600 dark:text-red-500"/>}
                                    </div>
                                </div>))
                        }
                        <div className="grid grid-cols-6 lg:grid-cols-4" key={features.length}>
                            <div
                                className="lg:p-4 p-1 text-left border-r-2  border-t-2 font-light col-span-3 lg:col-span-1">
                                {t('services.pages.intranet.functions.hosting.title')} <i
                                className="uil uil-question-circle text-lg text-blue-700 dark:text-blue-200 hover:text-brandColor-500 dark:hover:text-brandColor-500"
                                onMouseOver={() => setActiveQuestion(features.length - 1)}
                                onMouseOut={() => setActiveQuestion(-1)}/>
                            </div>
                            <div className="p-1 text-center border-r-2  border-t-2">
                                <i className="uil uil-cloud text-4xl text-blue-600 dark:text-blue-500"/>
                            </div>
                            <div className="p-1 text-center border-r-2  border-t-2">
                                <i className="uil uil-cloud text-4xl text-blue-600 dark:text-blue-500"/>
                            </div>
                            <div className="p-1 text-center border-t-2">
                                <i className="uil uil-cloud text-4xl text-blue-600 dark:text-blue-500 lg:mr-3"/>
                                <i className="uil uil-server text-4xl text-blue-600 dark:text-blue-500"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`fixed p-4 z-50 max-w-md bg-brandColor-900 rounded-md hidden ${activeQuestion !== -1 ? 'lg:block' : ''}`}
                    style={activeQuestion !== -1 ? {left: mousePosition.x + 20, top: mousePosition.y + 20} : {}}>
                    <p className="text-slate-50">{features[activeQuestion]?.description}</p>
                </div>
                <div
                    className={`fixed p-4 m-1 z-50 max-w-md bg-brandColor-900 rounded-md ${activeQuestion !== -1 ? 'lg:hidden' : 'block'}`}
                    style={activeQuestion !== -1 ? {left: 0, bottom: 0} : {}}>
                    <p className="text-slate-50">{features[activeQuestion]?.description}</p>
                </div>
            </section>
        </>
    )
}
