import React from 'react';
import {Link} from "react-router-dom";
import {Hexagon} from 'react-feather';
import {useTranslation} from "react-i18next";

export default function Detail() {
    const [t] = useTranslation('common');

    const services = [
        {
            icon: 'cloud-computing',
            title: t('about.services.intranet.title'),
            description: t('about.services.intranet.text'),
            link: "/services/intranet"
        },
        {
            icon: 'cloud-question',
            title: t('about.services.enterpriseItSupport.title'),
            description: t('about.services.enterpriseItSupport.text'),
            link: "/services/enterprise-it-support"
        },
        {
            icon: 'lightbulb-alt',
            title: t('about.services.consulting.title'),
            description: t('about.services.consulting.text'),
            link: "/services/consulting"
        },
        {
            icon: 'monitor',
            title: t('about.services.webDevelopment.title'),
            description: t('about.services.webDevelopment.text'),
            link: "/services/web-applications"
        },
        {
            icon: 'mobile-android',
            title: t('about.services.mobileDevelopment.title'),
            description: t('about.services.mobileDevelopment.text'),
            link: "/services/mobile-applications"
        },
        {
            icon: 'robot',
            title: t('about.services.ai.title'),
            description: t('about.services.ai.text'),
            link: "/services/artificial-intelligence"
        },
        {
            icon: 'graduation-cap',
            title: t('about.services.training.title'),
            description: t('about.services.training.text'),
            link: "/services/training"
        },
        {
            icon: 'server-network-alt',
            title: t('about.services.infrastructure.title'),
            description: t('about.services.infrastructure.text'),
            link: "/services/infrastructure-management"
        },
        {
            icon: 'document-info',
            title: t('about.services.audit.title'),
            description: t('about.services.audit.text'),
            link: "/services/audit"
        },
        {
            icon: 'shield-check',
            title: t('about.services.signalist.title'),
            description: t('about.services.signalist.text'),
            link: "/services/signalist"
        }
    ];

    return (
        <>
            {services.map((service, index) => {
                if (service.empty) {
                    return <div className={'hidden lg:block'}></div>
                }

                return (
                    <div key={index}
                         className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <Hexagon className="h-28 w-28 fill-brandColor-600/5 mx-auto rotate-[30deg]"
                            ></Hexagon>
                            <div
                                className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-brandColor-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                <i className={`uil uil-${service.icon}`}></i>
                            </div>
                        </div>
                        <div className="mt-6">
                            <Link to={service.link}
                                  className="text-lg h5 transition duration-500 ease-in-out hover:text-brandColor-500">
                                {service.title}
                            </Link>
                            <p className="text-slate-500 dark:text-slate-300 transition duration-500 ease-in-out mt-3">
                                {service.description}
                            </p>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
