import React from "react";
import {useTranslation} from "react-i18next";

import Marquee from "react-fast-marquee";
import {Carousel} from "react-responsive-carousel";

export default function Technologies({technologies}) {
    const [t] = useTranslation('common');

    const getSlide = (item, i) => {
        const slides = technologies.slice(i, i + 3);

        return (
            <div className="flex flex-row justify-between content-between" key={`slide-${i}`}>
                {slides.map((item, i) => (
                    <div
                        className="hexagon w-28 h-28 flex justify-center content-center bg-gray-100 dark:bg-gray-400/40" key={`img-carousel-${i}`}>
                        <img src={item.image} alt={item.name}
                             className={`w-16 h-auto self-center`}
                             aria-label={`Technology - ${item.name}`} key={`img-c-${i}`}/>
                    </div>
                ))}
            </div>
        )
    }
    return (
        <section className="relative md:py-24 py-16 md:pt-0 pt-0 dark:bg-slate-900 bg-white mt-1">
            <div className="grid grid-cols-1 justify-center">
                <div className="relative z-1">
                    <div className="content md:mt-8 container">
                        <div
                            className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                            <div className="lg:col-start-2 lg:col-span-10">
                                <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                                    <div>
                                        <div className="section-title text-md-start">
                                            <h3 className="text-slate-800/90 dark:text-slate-200/70">{t('services.pages.webDevelopment.technologies.title')}</h3>
                                            <h4 className="md:text-2xl text-xl font-medium text-slate-800 dark:text-slate-200 mt-2">{t('services.pages.webDevelopment.technologies.text.l1')}
                                                <br/> {t('services.pages.webDevelopment.technologies.text.l2')}</h4>
                                        </div>
                                    </div>

                                    <div className="section-title text-md-start">
                                        <p className="text-slate-600 dark:text-slate-200 max-w-xl mx-auto mb-2">{t('services.pages.webDevelopment.technologies.subtitle')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center lg:mt-10 mt-3">
                        <div className="col-span-12">
                            <div className="relative z-50 hidden lg:block">
                                <Marquee>
                                    <div className="flex flex-row content-between justify-between min-w-[100vw] gap-1">
                                        {technologies.map((item, i) => (
                                            <div
                                                className="hexagon w-36 h-36 flex justify-center content-center bg-gray-100 dark:bg-gray-400/40" key={`img-wrapper-${i}`}>
                                                <img src={item.image} alt={item.name}
                                                     className={`w-20 h-auto self-center`}
                                                     aria-label={`Technology - ${item.name}`} key={`img-${i}`}/>
                                            </div>
                                        ))}
                                        <div/>
                                    </div>
                                </Marquee>
                            </div>
                            <div className="lg:hidden mx-auto">
                                <Carousel
                                    showArrows={false}
                                    showStatus={false}
                                    showThumbs={false}
                                    showIndicators={false}
                                    infiniteLoop={true}
                                    autoPlay={true}
                                >
                                    {technologies
                                        .filter((item, i) => i % 3 === 0)
                                        .map((item, i) => (getSlide(item, i * 3)))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
