import React, {useContext} from 'react'
import image2 from '../assets/images/blog/release1.webp';
import image2Xl from '../assets/images/blog/release1Xl.webp';
import {Link} from "react-router-dom";
import {LanguageContext} from "../App";
import {useTranslation} from "react-i18next";

export const NewsData = {
    'pl': [
        // {
        //     image: image1,
        //     imageXl: image1Xl,
        //     title: 'Retoric sp. z o.o. oficjalnie zarejestrowana',
        //     titleAlt: 'Retoric sp. z o.o. incorporated',
        //     alt: "Cztery osoby w strojach biznesowych podpisują umowę wokół okrągłego stołu",
        //     date: new Date('2023-09-05'),
        //     detail: 'Nasza firma została oficjalnie zarejestrowana 10 września 2021 roku. Cieszymy się na współpracę z Państwem.',
        //     content: '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    10 września 2023 roku, został złożony oficjalny wniosek o rejestracje spółki' +
        //         '    Retoric sp z o.o.' +
        //         '    Retoric powstało w wyniku połączenia pasji do technologi i rozwiązywania' +
        //         '    codziennych problemów' +
        //         '    z jej pomocą. Naszym celem jest tworzenie oprogramowania, które będzie w stanie' +
        //         '    pomóc działania' +
        //         '    operacyjne Twojej organizacji.' +
        //         '</p>' +
        //         '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    Nasza firma powstała w 2023 roku, jednak nie oznacza to, że brak nam' +
        //         '    doświadczenia w branży.' +
        //         '    W naszym zespole znajdują się osoby, które pracowały w światowych firmach' +
        //         '    technologicznych' +
        //         '    takich jak <a href="https://checkout.com" target="_blank"' +
        //         '                     class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">Checkout.com</a> lub <a' +
        //         '    href="https://www.about.sainsburys.co.uk/" target="_blank"' +
        //         '    class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">J' +
        //         '    Sainsbury PLC</a>.' +
        //         '</p>' +
        //         '<div' +
        //         '    class="text-slate-500 dark:text-slate-300 italic border-x-4 border-violet-600 rounded-ss-xl rounded-ee-xl mt-3 p-3 mb-6">' +
        //         '    <p class="text-justify">"Moja największa pasja? Zdecydowanie tworzenie oprogramowania, które' +
        //         '        rozwiązuje prawdziwe problemy.' +
        //         '        Ta pasja zrodziła się we mnie dzięki mojemu tacie, który gdy miałem 9 lat' +
        //         '        pokazał mi magię' +
        //         '        tworzenia stron internetowych. Nawiasem mówiąc, szybko się tym znudziłem i' +
        //         '        zacząłem rozwijać się' +
        //         '        w zupełnie innym kierunku w dziedzine Software Developmentu. Obecnie zajmuje' +
        //         '        się głównie backendem' +
        //         '        gdzie mam okazje przetestować swoje umiejętności i doświadczenie w tworzeniu' +
        //         '        aplikacji, które' +
        //         '        muszą obsługiwać tysiące zapytań na sekundę.' +
        //         '        "</p>' +
        //         '    <span class="text-right block mx-auto mt-2">Igor Topolski - CTO Retoric sp. z o.o.</span>' +
        //         '</div>' +
        //         '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    Specjalistyczną wiedzę branżową zdobyliśmy dzięki wieloletniemu doświadczeniu w' +
        //         '    pracy i zarzązaniu w różnych instytucjach publicznych i prywatnych firmach.' +
        //         '    Dzięki temu jesteśmy w stanie zrozumieć potrzeby naszych klientów, a zarazem' +
        //         '    dostarczyć gotowe rozwiązania, które pomogą w rozwoju ich biznesu.' +
        //         '</p>' +
        //         '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    Gorąco zapraszamy Państwa do zapoznania się z naszą <a href="/services"' +
        //         '                                                              class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">ofertą</a> oraz' +
        //         '    do kontaktu z nami. Bardzo chętnie odpowiemy na wszystkie pytania oraz' +
        //         '    przedstawimy ofertę naszego produktu.' +
        //         '</p>',
        //     readTime: 3,
        //     author: 1
        // },
        {
            image: image2,
            imageXl: image2Xl,
            title: 'Pierwsze wydanie naszego Retoric Intranet',
            titleAlt: 'First release of our Retoric Intranet',
            alt: "Laptop na środku głównego placu w mieście, kolorowe balony przelatują nad nim.",
            date: new Date('2023-09-05'),
            detail: 'Pierwsza wersja naszego oprogramowania została oficjalnie wydana. Oto lista wszystkich funkcji.',
            content: '<h3 class="text-slate-500 dark:text-slate-300 h3">Dzisiaj opublikowaliśmy pierwszą produkcyjną wersję Retoric Intranet</h3>' +
                '        <p class="text-slate-500 dark:text-slate-300 text-justify mb-8">Retoric Intranet to jedyny rozwiązanie, którego potrzebujesz, aby usprawnić codzienne' +
                '            operacje. Oferuje szeroki zakres funkcji, dzięki czemu jest to jedyny portal, którego' +
                '            potrzebują Twoi pracownicy.</p>' +
                '    <h4 class="text-slate-500 dark:text-slate-300 h4">Modele cenowe</h4>' +
                '' +
                '    <p class="text-slate-500 dark:text-slate-300 text-justify mb-8 font-light">Retoric Intranet jest dostępny w trzech modelach cenowych, dostosowanych do' +
                '        unikalnych potrzeb Twojej organizacji. Jeśli nie jesteś pewien, która wersja jest odpowiednia dla' +
                '        Twojej firmy, zachęcamy do skorzystania z naszego okresu próbnego lub' +
                '        skontaktowania się z nami, aby lepiej zrozumieć potrzeby Twojego biznesu.</p>' +
                '' +
                '    <h4 class="text-slate-500 dark:text-slate-300 h4">Najważniejsze funkcje</h4>' +
                '' +
                '    <ul class="text-slate-500 dark:text-slate-300 text-left mb-8 font-light">' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Wyszukiwanie</span> Wbudowane wyszukiwanie internetowe na stronie głównej intranetu, umożliwiające szybkie' +
                '            odnajdywanie informacji.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Aktualności</span> Zarządzaj i wyświetlaj aktualizacje informacyjne na stronie głównej intranetu.</li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Kalendarz</span> Skutecznie zarządzaj wydarzeniami w swojej organizacji, z' +
                '            opcjami integracji z kalendarzami takimi jak Google i Outlook (dostępne w' +
                '            wersji Business+).' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Zadania</span> Twórz, przypisuj i śledź zadania bez wysiłku.</li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Asystent AI</span> Funkcja oparta na czacie wykorzystująca sztuczną inteligencję do' +
                '            odpowiadania na zapytania użytkowników. Licencja obejmuje ograniczoną liczbę miesięcznych' +
                '            kredytów, z możliwością zakupu dodatkowych kredytów.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Informacje</span> Zarządzaj wewnętrznymi informacjami, takimi jak numery wewnętrzne,' +
                '            regulacje, instrukcje i wiele innych.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Szybkie linki</span> Konfiguruj linki w różnych sekcjach swojego intranetu.</li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Przewodniki</span> Twórz i edytuj różne przewodniki dla swoich pracowników.</li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Oprogramowanie</span> Zarządzaj repozytorium oprogramowania oferowanego Twoim pracownikom.</li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Integracje</span> Integruj się z zewnętrznymi platformami, takimi jak ActiveDirectory lub' +
                '            LDAP.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Prośby urlopowe</span> Łatwo twórz i aktualizuj prośby urlopowe, śledź pozostałe' +
                '            dni urlopowe i generuj prośby w formie plików PDF.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Prośby o delegację</span> Twórz prośby o delegację, oblicz opłaty za delegację' +
                '            i generuj prośby w formie plików PDF.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Sygnalizator</span> Moduł do obsługi zgłoszeń od informatorów, umożliwiający tworzenie' +
                '            i zarządzanie anonimowymi zgłoszeniami.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Kanban</span> Uprość zarządzanie zadaniami dzięki tablicy Kanban.</li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Zamówienia</span> Zarządzaj procesami zamówień w firmie.</li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Geotool</span> Moduł dedykowany instytucjom publicznym. Więcej informacji' +
                '            dostępnych po wcześniejszym kontakcie.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Opcje hostingowe</span> Wybieraj między opcjami hostingu - dostawa intranetu na' +
                '            serwerze Retoric lub serwerze klienta.' +
                '        </li>' +
                '        <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Dostosowanie</span> Dostosuj intranet do indywidualnych potrzeb klienta za dodatkową' +
                '            opłatą.' +
                '        </li>' +
                '    </ul>' +
                '    <p class="text-slate-500 dark:text-slate-300 text-justify mb-4">Jesteśmy podekscytowani rozpoczęciem tej podróży razem z Tobą i nie możemy się doczekać, aby pomóc' +
                '        Twojej organizacji rozwijać się dzięki naszym innowacyjnym rozwiązaniom. Zachęcamy do zapoznania się z naszymi' +
                '        <a href="/services" class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">usługami</a> i śmiało' +
                '        <a href="/contactus" class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">skontaktuj się z nami</a> w razie jakichkolwiek' +
                '        pytań. Jesteśmy tutaj, aby wspierać sukces Twojej firmy!</p>',
            readTime: 6,
            author: 2
        }
    ],
    'en': [
        // {
        //     image: image1,
        //     imageXl: image1Xl,
        //     title: 'Retoric sp. z o.o. incorporated',
        //     titleAlt: 'Retoric sp. z o.o. oficjalnie zarejestrowana',
        //     alt: "Four people wearing business apparel signing a contract around a round table",
        //     date: new Date('2023-09-10'),
        //     detail: 'Our company has been incorporated on 10th September 2021. We are looking forward to working with you',
        //     content: '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    On September 10, 2023, the official application for the registration of the company Retoric sp. z o.o. was submitted.' +
        //         '    Retoric was born from a combination of passion for technology and solving everyday problems with its help.' +
        //         '    Our goal is to create software that can assist in the operational activities of your organization.' +
        //         '</p>' +
        //         '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    Our company was founded in 2023, but this doesn\'t mean we lack experience in the industry.' +
        //         '    Our team includes individuals who have worked in global technology companies such as' +
        //         '    <a href="https://checkout.com" target="_blank" class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">Checkout.com</a> and <a' +
        //         '            href="https://www.about.sainsburys.co.uk/" target="_blank"' +
        //         '            class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">J Sainsbury PLC</a>.' +
        //         '</p>' +
        //         '<div' +
        //         '    class="text-slate-500 dark:text-slate-300 italic border-x-4 border-violet-600 rounded-ss-xl rounded-ee-xl mt-3 p-3 mb-6">' +
        //         '    <p class="text-justify">"My greatest passion? Definitely creating software that solves real problems.' +
        //         '        This passion was born in me thanks to my dad, who showed me the magic of' +
        //         '        creating websites when I was 9. By the way, I quickly got bored with it and' +
        //         '        started developing in a completely different direction in the field of Software Development.' +
        //         '        Currently, I mainly focus on backend development where I have the opportunity to test my skills' +
        //         '        and experience in creating applications that need to handle thousands of queries per second."' +
        //         '    </p>' +
        //         '    <span class="text-right block mx-auto mt-2">Igor Topolski - CTO of Retoric sp. z o.o.</span>' +
        //         '</div>' +
        //         '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    We have gained specialized industry knowledge through years of experience working and managing' +
        //         '    in various public institutions and private companies. This allows us to understand our clients\' needs' +
        //         '    and deliver ready-made solutions that will help in the development of their businesses.' +
        //         '</p>' +
        //         '<p class="text-slate-500 dark:text-slate-300 text-justify mb-4">' +
        //         '    We warmly invite you to explore our <a href="/services"' +
        //         '                                           class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">services</a> and' +
        //         '    to get in touch with us. We will be happy to answer all your questions and present our product offering.' +
        //         '</p>',
        //     readTime: 3,
        //     author: 1
        // },
        {
            image: image2,
            imageXl: image2Xl,
            title: 'First release of our Retoric Intranet',
            titleAlt: 'Pierwsze wydanie naszego Retoric Intranet',
            alt: "A laptop in the middle of the main square in a city, colorful balloons are flying over it.",
            date: new Date('2023-09-05'),
            detail: 'The first version of our software has been officially released. Here\'s a list of all features.',
            content: '<h3 class="text-slate-500 dark:text-slate-300 h3">We released the first production version of Retoric Intranet today</h3>' +
                '        <p class="text-slate-500 dark:text-slate-300 text-justify mb-8">Retoric Intranet is the only solution you need to streamline your daily' +
                '            operations. It offers a wide range of features, making it the only portal your' +
                '            employees will ever need.</p>' +
                '' +
                '        <h4 class="text-slate-500 dark:text-slate-300 h4">Pricing Models</h4>' +
                '' +
                '        <p class="text-slate-500 dark:text-slate-300 text-justify mb-8 font-light">Retoric Intranet is available in three pricing models, each tailored to the' +
                '            unique needs of your organization. If you\'re unsure which version is right for' +
                '            your company, we encourage you to take advantage of our free trial period or' +
                '            reach out to us to understand your business needs better.</p>' +
                '' +
                '        <h4 class="text-slate-500 dark:text-slate-300 h4">Feature Highlights</h4>' +
                '' +
                '        <ul class="text-slate-500 dark:text-slate-300 text-left mb-8 font-light">' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Search</span> Built-in web search on your intranet\'s homepage, enabling quick' +
                '                information retrieval.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- News</span> Manage and display news updates on your intranet\'s homepage.</li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Calendar</span> Efficiently manage events within your organization, with' +
                '                integration options for calendars like Google and Outlook (available in' +
                '                Business+ version).' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Tasks</span> Create, assign, and track tasks effortlessly.</li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- AI Assistant</span> A chat-based function leveraging artificial intelligence to' +
                '                respond to user queries. The license comes with a limited number of monthly' +
                '                credits, with the option to purchase additional credits.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Information</span> Manage internal information such as internal numbers,' +
                '                regulations, instructions, and more.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Quick Links</span> Configure links in various sections of your intranet.</li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Guides</span> Create and edit various guides for your employees.</li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Software</span> Manage a software repository offered to your employees.</li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Integrations</span> Integrate with external platforms like ActiveDirectory or' +
                '                LDAP.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Holiday Requests</span> Easily create and update holiday requests, track leave' +
                '                balances, and generate PDF requests.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Delegation Requests</span> Create delegation requests, calculate delegation fees,' +
                '                and generate PDF requests.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Signalist</span> A module for handling whistleblower reports, allowing the creation' +
                '                and management of anonymous reports.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Kanban</span> Simplify task management with a Kanban board.</li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Orders</span> Manage the company\'s order processes.</li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Geotool</span> A module exclusively for public institutions. More information' +
                '                available upon prior contact.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Hosting Options</span> Choose between hosting options – intranet delivery on the' +
                '                Retoric server or the client\'s server.' +
                '            </li>' +
                '            <li><span class="text-slate-500 dark:text-slate-300 font-bold">- Customization</span> Tailor your intranet to individual client needs for an' +
                '                additional fee.' +
                '            </li>' +
                '        </ul>' +
                '        <p class="text-slate-500 dark:text-slate-300 text-justify mb-4">We are excited to embark on this journey with you and look forward to helping' +
                '            your organization thrive with our innovative solutions. Please explore our' +
                '            <a href="/services" class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">services</a> and feel free to <a href="/contact" class="font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out">contact us</a> with any' +
                '            questions. We\'re here to support your business\'s success!</p>',
            readTime: 6,
            author: 2
        }
    ]
}

export const ProcessBlogTitle = (title) => {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
}

export const FormatBlogDate = (date, lang) => {
    const locale = lang === 'pl' ? 'pl-PL' : 'en-GB';

    return date.toLocaleDateString(locale, {year: 'numeric', month: 'long', day: 'numeric'});
}

export default function BlogsNews() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');

    return (
        <>
            <div className="container lg:py-24 py-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('news.title')}</h3>

                    <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">{t('news.subtitle')}</p>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] mt-8">
                    {NewsData[lang].map((item, index) => (
                        <div
                            className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-gray-800 overflow-hidden flex flex-col justify-between"
                            key={index}>
                            <img src={item.image} alt={item.alt} className="w-fit h-auto"/>

                            <div className="content p-6 flex flex-col justify-between flex-1">
                                <div className="flex-1">
                                    <Link to={`/blog/${ProcessBlogTitle(item.title)}`}
                                          className="text-lg hover:text-brandColor-600 dark:text-white dark:hover:text-brandColor-600 transition duration-500 ease-in-out font-medium h5">{item.title}</Link>
                                    <p className="text-slate-500 dark:text-slate-300 mt-3 flex-1">{item.detail}</p>
                                </div>

                                <div className="mt-5">
                                    <Link to={`/blog/${ProcessBlogTitle(item.title)}`}
                                          className="hover:text-brandColor-600 dark:hover:text-brandColor-600 after:text-brandColor-600 dark:text-white transition duration-500">{t('news.more')}<i
                                        className="uil uil-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
