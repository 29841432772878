import logo_icon_64 from '../assets/images/logotype-light.svg';
import React from 'react'
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Footer() {
    const [t] = useTranslation('common');

    return (
        <>
            <footer className="footer bg-brandColor-950 relative text-slate-200 dark:text-slate-200">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            <div className="py-[60px] px-0">
                                <div className="grid grid-cols-1">
                                    <div className="text-center mb-10">
                                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                                            <div className="flex justify-center content-center">
                                                <img src={logo_icon_64} className="block mx-auto w-[64px] h-auto"
                                                     alt={t('general.accessibility.logo')}/>
                                            </div>
                                            <div className="flex justify-center content-center">
                                                <p className="text-slate-200 mt-4">Retoric sp. z o.o.<br/>ul. Adama
                                                    Mickiewicza 29<br /><span>{t('contact.addressText.office')}</span><br/>40-085 Katowice</p>
                                            </div>
                                            <div className="flex justify-center content-center">
                                                <p className="text-slate-200 mt-4 text-left">NIP: 634 30 30 073<br/>REGON: 526711314<br />KRS: 0001060555
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <ul className="list-none footer-list text-center mb-8 flex flex-col lg:block">
                                        <li className="inline px-2"><Link to="/services"
                                                                          className="text-slate-200 hover:text-slate-300 duration-500 ease-in-out">{t('nav.services')}</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/aboutus"
                                                                                    className="text-slate-200 hover:text-slate-300 duration-500 ease-in-out ms-2">{t('nav.about')}</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/blogs"
                                                                                    className="text-slate-200 hover:text-slate-300 duration-500 ease-in-out ms-2">{t('nav.blog')}</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/careers"
                                                                                    className="text-slate-200 hover:text-slate-300 duration-500 ease-in-out ms-2">{t('nav.careers')}</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/contactus"
                                                                                    className="text-slate-200 hover:text-slate-300 duration-500 ease-in-out ms-2">{t('nav.contact')}</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/privacy-policy"
                                                                                    className="text-slate-200 hover:text-slate-300 duration-500 ease-in-out ms-2">{t('nav.privacyPolicy')}</Link>
                                        </li>
                                    </ul>

                                    <div>
                                        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                                            <div className="flex flex-col justify-start content-start gap-1">
                                                <h4 className="h4 text-white">{t('nav.footer.software.header')}</h4>
                                                <ul className="gap-0.5">
                                                    <li>
                                                        <Link to="/services/intranet"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.software.intranet')}</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services/web-applications"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.software.web')}</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services/mobile-applications"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.software.mobile')}</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services/artificial-intelligence"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.software.ai')}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="flex flex-col justify-start content-start gap-1">
                                                <h4 className="h4 text-white">{t('nav.footer.services.header')}</h4>
                                                <ul className="gap-0.5">
                                                    <li>
                                                        <Link to="/services/consulting"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.services.consulting')}</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services/audit"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.services.audits')}</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services/enterprise-it-support"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.services.outsourcing')}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="flex flex-col justify-start content-start gap-1">
                                                <h4 className="h4 text-white">{t('nav.footer.business.header')}</h4>
                                                <ul className="gap-0.5">
                                                    <li>
                                                        <Link to="/services/intranet"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.business.training')}</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/services/web-applications"
                                                              className="text-slate-100 hover:text-slate-300 duration-500 ease-in-out">{t('nav.footer.business.infrastructure')}</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-[30px] px-0 border-t border-slate-800">
                    <div className="container text-center">
                        <div className="grid md:grid-cols-12 items-center">
                            <div className="md:col-span-6">
                                <div className="md:text-start text-center">
                                    <p className="text-slate-300">©
                                        {new Date().getFullYear()} Retoric sp. z o.o. {t('footer.copyrightNote')}
                                    </p>
                                </div>
                            </div>

                            <div className="md:col-span-6 md:mt-0 mt-8">
                                <ul className="list-none md:text-end text-center">
                                    <li className="inline ms-1"><Link to="https://linkedin.com/company/retoric" target="_blank"
                                                                      className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-slate-400 dark:hover:border-slate-400 hover:bg-linkedin dark:hover:bg-linkedin"><i
                                        className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                                    <li className="inline ms-1"><Link to={`mailto:${t('general.email')}`}
                                                                      className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-slate-400 dark:hover:border-slate-400 hover:bg-email dark:hover:bg-email hover:text-slate-800"><i
                                        className="uil uil-envelope align-middle" title="email"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
