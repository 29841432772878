import React, {useContext, useState} from 'react'
import {Link, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {BACKEND} from "../main";
import {LanguageContext} from "../App";
import spinner from "../assets/images/spinner.svg";

export default function GetInTouch({reversed, signalist}) {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');
    const location = useLocation();

    const [formError, setFormError] = useState('')
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    const handleErrors = (err) => {
        if (err.error === 'name-missing') {
            setFormError(t('contact.form.name.error.required'));
            return false;
        } else if (err.error === 'email-disposable') {
            setFormError(t('contact.form.email.error.disposable'));
            return false;
        } else if (err.error === 'email-missing') {
            setFormError(t('contact.form.email.error.required'));
            return false;
        } else if (err.error.startsWith('email')) {
            setFormError(t('contact.form.email.error.invalid'));
            return false;
        } else if (err.error === 'subject-missing') {
            setFormError(t('contact.form.subject.error.invalid'));
            return false;
        } else if (err.error === 'message-missing') {
            setFormError(t('contact.form.message.error.invalid'));
            return false;
        }

        setFormError('');
        return true;
    }

    const submitForm = (e) => {
        e.preventDefault();
        setSent(false);

        const message = {
            name: e.target.name.value,
            company: e.target.company.value,
            email: e.target.email.value,
            subject: e.target.subject.value,
            message: e.target.message.value,
            lang: lang,
            ref: location.pathname
        }

        if (!message.name) {
            setFormError(t('contact.form.name.error.required'));
            return;
        }

        if (!message.email) {
            setFormError(t('contact.form.email.error.required'));
            return;
        }

        if (!message.subject) {
            setFormError(t('contact.form.subject.error.required'));
            return;
        }

        if (!message.message) {
            setFormError(t('contact.form.message.error.required'));
            return;
        }

        setFormError('');
        setSending(true);

        fetch(`${BACKEND}/msg`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        })
            .then(res => res.json())
            .then(data => {
                setSending(false);

                if (data.success) {
                    setSent(true);
                    e.target.name.value = '';
                    e.target.company.value = '';
                    e.target.email.value = '';
                    e.target.subject.value = '';
                    e.target.message.value = '';

                    return;
                }

                if (!handleErrors(data))
                    return;

                setSent(true);
            })
            .catch(err => {
                handleErrors(err);
                setFormError(t('contact.form.error'));
                setSending(false);
                setSent(false);
            });
    }

    return (
        <>
            <section className={`relative md:py-24 py-16 bg-gray-50 ${reversed ? 'bg-white dark:bg-slate-900' : 'bg-gray-50 dark:bg-slate-800'}`} id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('contact.getInTouch')}!</h3>
                        <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">{t('contact.subtitle')}</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
                        <div className="lg:col-span-8">
                            <div className={`p-6 rounded-md shadow ${reversed ? 'bg-gray-50 dark:bg-slate-800' : 'bg-white dark:bg-slate-900'}`}>
                                <form method="post" name="myForm" id="myForm" onSubmit={(e) => submitForm(e)}>
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div className="grid lg:grid-cols-12 lg:gap-[30px]">
                                        <div className="lg:col-span-6 mb-5">
                                            <label
                                                className="dark:text-white font-medium">{t('contact.form.name.label')}<span
                                                className="text-bold text-brandColor-600">*</span></label>
                                            <input name="name" id="name" type="text"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-950/50 dark:focus:border-brandColor-200/50 focus:ring-0"
                                                   placeholder={t('contact.form.name.placeholder')}/>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <label
                                                className="dark:text-white font-medium">{t('contact.form.email.label')}<span
                                                className="text-bold text-brandColor-600">*</span></label>
                                            <input name="email" id="email" type="email"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-950/50 dark:focus:border-brandColor-200/50 focus:ring-0"
                                                   placeholder={t('contact.form.email.placeholder')}/>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <label
                                                className="dark:text-white font-medium">{t('contact.form.company.label')}</label>
                                            <input name="company" id="company"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-950/50 dark:focus:border-brandColor-200/50 focus:ring-0"
                                                   placeholder={t('contact.form.company.placeholder')}/>
                                        </div>
                                        <div className="mb-5">
                                            <label
                                                className="dark:text-white font-medium">{t('contact.form.subject.label')}<span
                                                className="text-bold text-brandColor-600">*</span></label>
                                            <input name="subject" id="subject"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-950/50 dark:focus:border-brandColor-200/50 focus:ring-0"
                                                   placeholder={signalist ? t('contact.form.subject.signalist.placeholder') : t('contact.form.subject.placeholder')}/>
                                        </div>

                                        <div className="mb-5">
                                            <label
                                                className="dark:text-white font-medium">{t('contact.form.message.label')}<span
                                                className="text-bold text-brandColor-600">*</span></label>
                                            <textarea name="message" id="message"
                                                      className="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-950/50 dark:focus:border-brandColor-200/50 focus:ring-0 h-28"
                                                      placeholder={reversed ? t('contact.form.message.signalist.placeholder') : t('contact.form.message.placeholder')}></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" disabled={sending}
                                            className="min-w-[185px] py-2 mb-3 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:border-brandColor-800 text-white rounded-md">
                                        {sending ? <img src={spinner} alt="Spinner"
                                                        className="mx-auto"/> : t('contact.form.send')}
                                    </button>
                                    <div>
                                        <span
                                            className={`${sent ? 'text-green-700 dark:text-green-400' : 'text-red-700 dark:text-red-400'}`}>{formError ? formError : sent ? t('contact.form.sent') : ''}</span>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <span
                                            className="text-lg dark:text-white mb-2 font-medium block">{t('contact.form.phone')}</span>
                                        <Link to={`tel:${t('general.phoneNumber')}`}
                                              className="text-slate-500 dark:text-slate-300 hover:text-brandColor-600">{t('general.phoneNumber')}</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <span
                                            className="text-lg dark:text-white mb-2 font-medium block">{t('contact.form.emailAddress')}</span>
                                        <Link to={`mailto:${t('general.email')}`}
                                              className="text-slate-500 dark:text-slate-300 hover:text-brandColor-600">{t('general.email')}</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <span
                                            className="text-lg dark:text-white mb-2 font-medium block">{t('contact.form.address')}</span>
                                        <p className="text-slate-500 dark:text-slate-300 mb-2">{t('contact.streetAddress')}<br/>40-085
                                            Katowice<br/>{t('general.poland')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
