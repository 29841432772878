import contact from '../assets/images/contact-us.webp';
import React, {useContext, useState} from 'react'
import Navbar from '../component/navbar'
import ContactData from '../component/contact-data';
import Footer from '../component/footer';
import {useTranslation} from "react-i18next";
import {LanguageContext} from "../App";
import {useLocation} from "react-router-dom";
import {BACKEND} from "./index";
import spinner from '../assets/images/spinner.svg'

export default function ContactUs() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');
    const location = useLocation();

    const [formError, setFormError] = useState('')
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    const handleErrors = (err) => {
        if (err.error === 'name-missing') {
            setFormError(t('contact.form.name.error.required'));
            return false;
        } else if (err.error === 'email-disposable') {
            setFormError(t('contact.form.email.error.disposable'));
            return false;
        } else if (err.error === 'email-missing') {
            setFormError(t('contact.form.email.error.required'));
            return false;
        } else if (err.error.startsWith('email')) {
            setFormError(t('contact.form.email.error.invalid'));
            return false;
        } else if (err.error === 'subject-missing') {
            setFormError(t('contact.form.subject.error.invalid'));
            return false;
        } else if (err.error === 'message-missing') {
            setFormError(t('contact.form.message.error.invalid'));
            return false;
        }

        setFormError('');
        return true;
    }

    const submitForm = (e) => {
        e.preventDefault();
        setSent(false);

        const message = {
            name: e.target.name.value,
            company: e.target.company.value,
            email: e.target.email.value,
            subject: e.target.subject.value,
            message: e.target.message.value,
            lang: lang,
            ref: location.pathname
        }

        if (!message.name) {
            setFormError(t('contact.form.name.error.required'));
            return;
        }

        if (!message.email) {
            setFormError(t('contact.form.email.error.required'));
            return;
        }

        if (!message.subject) {
            setFormError(t('contact.form.subject.error.required'));
            return;
        }

        if (!message.message) {
            setFormError(t('contact.form.message.error.required'));
            return;
        }

        setFormError('');
        setSending(true);

        fetch(`${BACKEND}/msg`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        })
            .then(res => res.json())
            .then(data => {
                setSending(false);

                if (data.success) {
                    setSent(true);
                    e.target.name.value = '';
                    e.target.company.value = '';
                    e.target.email.value = '';
                    e.target.subject.value = '';
                    e.target.message.value = '';

                    return;
                }

                if (!handleErrors(data))
                    return;

                setSent(true);
            })
            .catch(err => {
                handleErrors(err);
                setFormError(t('contact.form.error'));
                setSending(false);
                setSent(false);
            });
    }

    return (
        <>
            <Navbar/>
            <section
                className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-contact.webp')] bg-top bg-cover bg-no-repeat"
                id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">{t('contact.title')}</h3>
                    </div>
                </div>
            </section>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt={t('general.accessibility.alt.contact')} className="w-fit h-auto"/>
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">{t('contact.getInTouch')} !</h3>

                                    <form method="post" name="myForm" id="myForm" onSubmit={(e) => submitForm(e)}>
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="name"
                                                       className="form-label font-medium">{t('contact.form.name.label')}<span
                                                    className="text-bold text-brandColor-600">*</span></label>
                                                <input name="name" id="name" type="text"
                                                       className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                       placeholder={t('contact.form.name.placeholder')}/>
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="email"
                                                       className="form-label font-medium">{t('contact.form.email.label')}<span
                                                    className="text-bold text-brandColor-600">*</span></label>
                                                <input name="email" id="email" type="email"
                                                       className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                       placeholder={t('contact.form.email.placeholder')}/>
                                            </div>
                                            <div className="mb-5">
                                                <label htmlFor="subject"
                                                       className="form-label font-medium">{t('contact.form.company.label')}</label>
                                                <input name="company" id="company"
                                                       className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                       placeholder={t('contact.form.company.placeholder')}/>
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="subject"
                                                       className="form-label font-medium">{t('contact.form.subject.label')}<span
                                                    className="text-bold text-brandColor-600">*</span></label>
                                                <input name="subject" id="subject"
                                                       className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                       placeholder={t('contact.form.subject.placeholder')}/>
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="message"
                                                       className="form-label font-medium">{t('contact.form.message.label')}<span
                                                    className="text-bold text-brandColor-600">*</span></label>
                                                <textarea name="message" id="comments"
                                                          className="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2 textarea h-28"
                                                          placeholder={t('contact.form.message.placeholder')}></textarea>
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" disabled={sending}
                                                className="min-w-[185px] py-2 mb-3 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:border-brandColor-800 text-white rounded-md">
                                            {sending ? <img src={spinner} alt="Spinner"
                                                            className="mx-auto"/> : t('contact.form.send')}
                                        </button>
                                        <div>
                                            <span
                                                className={`${sent ? 'text-green-700 dark:text-green-400' : 'text-red-700 dark:text-red-400'}`}>{formError ? formError : sent ? t('contact.form.sent') : ''}</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactData/>
            </section>
            <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2550.5237266188396!2d19.010952777531475!3d50.263479000807294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716ce3eead102b9%3A0x9091ffbb8bf81c68!2sAdama%20Mickiewicza%2029%2C%2040-085%20Katowice%2C%20Poland!5e0!3m2!1sen!2suk!4v1693914498945!5m2!1sen!2suk"
                            style={{border: 0}} className="w-full h-[500px]" title="contactUs" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}
