import './App.css';
import React, {createContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import AboutUs from './main/aboutus';
import './assets/css/tailwind.css'
import './assets/libs/@mdi/font/css/materialdesignicons.min.css'
import './assets/libs/@iconscout/unicons/css/line.css'
import './assets/libs/tobii/css/tobii.min.css'
import './assets/images/favicon.ico'
import Blogs from './main/blogs';
import ContactUs from './main/contactus';
import Index from './main';
import Services from './main/services/services';
import Switcher from './component/Switcher';
import ScrollToTop from './component/Scroll-top';
import "../node_modules/flag-icons/css/flag-icons.min.css";
import Careers from "./main/careers";
import Intranet from "./main/services/intranet";
import BlogDetail from "./main/blog-detail";
import ArtificialIntelligence from "./main/services/artificial-intelligence";
import Consulting from "./main/services/consulting";
import EnterpriseItSupport from "./main/services/enterprise-it-support";
import InfrastructureManagement from "./main/services/infrastructure-management";
import Training from "./main/services/training";
import WebDevelopment from "./main/services/web-development";
import MobileDevelopment from "./main/services/mobile-development";
import Audit from "./main/services/audit";
import PrivacyPolicy from "./main/privacy-policy";
import PageNotFound from "./main/pagenotfound";
import Signalist from "./main/services/signalist";

export const getLanguage = () => {
    const lang = localStorage.getItem('retoric-lang') || window.navigator.language;

    if (lang.includes('pl')) {
        return 'pl';
    }

    return 'en';
}

export const LanguageContext = createContext({lang: 'en'});

export default function App() {
    const [lang, setLang] = useState(getLanguage());

    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
    }, []);

    return (
        <LanguageContext.Provider value={{lang, setLang}}>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    <Route path="/aboutus" element={<AboutUs/>}/>
                    <Route path="/blog/:title" element={<BlogDetail/>}/>
                    <Route path="/blogs" element={<Blogs/>}/>
                    <Route path="/contactus" element={<ContactUs/>}/>
                    <Route path="/index" element={<Index/>}/>
                    <Route path="/careers" element={<Careers/>}/>
                    <Route path="/services/artificial-intelligence" element={<ArtificialIntelligence/>}/>
                    <Route path="/services/audit" element={<Audit/>}/>
                    <Route path="/services/consulting" element={<Consulting/>}/>
                    <Route path="/services/enterprise-it-support" element={<EnterpriseItSupport/>}/>
                    <Route path="/services/infrastructure-management" element={<InfrastructureManagement/>}/>
                    <Route path="/services/intranet" element={<Intranet/>}/>
                    <Route path="/services/mobile-applications" element={<MobileDevelopment/>}/>
                    <Route path="/services/signalist" element={<Signalist/>}/>
                    <Route path="/services/training" element={<Training/>}/>
                    <Route path="/services/web-applications" element={<WebDevelopment/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/" element={<Index/>}/>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
                <Switcher/>
            </BrowserRouter>
        </LanguageContext.Provider>
    )
};
