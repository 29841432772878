import header from '../../assets/images/services/ai/ai.webp';
import imageProcessing from '../../assets/images/services/ai/image-processing.webp';
import languageProcessing from '../../assets/images/services/ai/nlm-processing.webp';
import dataProcessing from '../../assets/images/services/ai/data-processing.webp';
import commercialModels from '../../assets/images/services/ai/commercial-model.webp';
import React from 'react'
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Option from "../../component/web-development/option";
import Technologies from "../../component/technologies";

import dotnet from '../../assets/images/services/web-development/dotnet.svg';
import javascript from '../../assets/images/services/web-development/javascript.svg';
import python from '../../assets/images/services/web-development/python.svg';
import mongodb from '../../assets/images/services/web-development/mongodb.svg';
import keras from '../../assets/images/services/ai/keras.svg';
import tensorflow from '../../assets/images/services/ai/tensorflow.svg';
import pytorch from '../../assets/images/services/ai/pytorch.svg';
import opencv from '../../assets/images/services/ai/opencv.svg';
import scikit from '../../assets/images/services/ai/scikit.svg';
import pandas from '../../assets/images/services/ai/pandas.svg';
import numpy from '../../assets/images/services/ai/numpy.svg';
import matplotlib from '../../assets/images/services/ai/matplotlib.svg';
import RelatedServices from "../../component/related-services";

const technologies = [
    {
        name: 'Python',
        image: python
    },
    {
        name: 'JavaScript',
        image: javascript
    },
    {
        name: '.NET',
        image: dotnet
    },
    {
        name: "Keras",
        image: keras
    },
    {
        name: "TensorFlow",
        image: tensorflow
    },
    {
        name: "PyTorch",
        image: pytorch
    },
    {
        name: "OpenCV",
        image: opencv
    },
    {
        name: "Scikit-Learn",
        image: scikit
    },
    {
        name: "Pandas",
        image: pandas
    },
    {
        name: "NumPy",
        image: numpy
    },
    {
        name: "Matplotlib",
        image: matplotlib
    },
    {
        name: 'MongoDB',
        image: mongodb
    }
];

export default function ArtificialIntelligence() {
    const [t] = useTranslation('common');

    const relatedServices = [
        {
            icon: 'monitor',
            title: t('about.services.webDevelopment.title'),
            description: t('about.services.webDevelopment.text'),
            link: "/services/web-applications"
        },
        {
            icon: 'mobile-android',
            title: t('about.services.mobileDevelopment.title'),
            description: t('about.services.mobileDevelopment.text'),
            link: "/services/mobile-applications"
        },
        {
            icon: 'server-network-alt',
            title: t('about.services.infrastructure.title'),
            description: t('about.services.infrastructure.text'),
            link: "/services/infrastructure-management"
        }
    ];

    return (
        <>
            <link rel="preload" as="image" href={header}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 py-0 md:h-auto flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {t('services.pages.artificialIntelligence.header')} <span
                                    className="font-bold">{t('services.pages.artificialIntelligence.headerStrong')}</span>
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.artificialIntelligence.text')}</p>
                            </div>

                            <img src={header} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <Technologies technologies={technologies}/>
            <Option title={t('services.pages.artificialIntelligence.services.imageProcessing.title')}
                    image={imageProcessing}
                    imageAlt={t('services.pages.artificialIntelligence.services.imageProcessing.imageAlt')}
                    textLines={[
                        t('services.pages.artificialIntelligence.services.imageProcessing.text.1'),
                        t('services.pages.artificialIntelligence.services.imageProcessing.text.2'),
                        t('services.pages.artificialIntelligence.services.imageProcessing.text.3'),
                        t('services.pages.artificialIntelligence.services.imageProcessing.text.4'),
                        t('services.pages.artificialIntelligence.services.imageProcessing.text.5')
                    ]}
                    orientation={"right"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <Option title={t('services.pages.artificialIntelligence.services.languageProcessing.title')}
                    image={languageProcessing}
                    imageAlt={t('services.pages.artificialIntelligence.services.languageProcessing.imageAlt')}
                    textLines={[
                        t('services.pages.artificialIntelligence.services.languageProcessing.text.1'),
                        t('services.pages.artificialIntelligence.services.languageProcessing.text.2'),
                        t('services.pages.artificialIntelligence.services.languageProcessing.text.3')
                    ]}
                    orientation={"left"}
                    textClassName="dark:text-slate-100"
            />
            <Option title={t('services.pages.artificialIntelligence.services.dataProcessing.title')}
                    image={dataProcessing}
                    imageAlt={t('services.pages.artificialIntelligence.services.dataProcessing.imageAlt')}
                    textLines={[
                        t('services.pages.artificialIntelligence.services.dataProcessing.text.1'),
                        t('services.pages.artificialIntelligence.services.dataProcessing.text.2'),
                        t('services.pages.artificialIntelligence.services.dataProcessing.text.3')
                    ]}
                    orientation={"right"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <Option title={t('services.pages.artificialIntelligence.services.commercialModels.title')}
                    image={commercialModels}
                    imageAlt={t('services.pages.artificialIntelligence.services.commercialModels.imageAlt')}
                    textLines={[
                        t('services.pages.artificialIntelligence.services.commercialModels.text.1'),
                        t('services.pages.artificialIntelligence.services.commercialModels.text.2'),
                        t('services.pages.artificialIntelligence.services.commercialModels.text.3'),
                        t('services.pages.artificialIntelligence.services.commercialModels.text.4')
                    ]}
                    orientation={"left"}
                    textClassName="dark:text-slate-100"
            />
            <RelatedServices services={relatedServices} subtitle={t('services.relatedServices.subtitles.ai')}/>
            <GetInTouch/>
            <Footer/>
        </>
    )
}
