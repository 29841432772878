import React, {useContext} from 'react'
import {Link} from "react-router-dom";
import {LanguageContext} from "../App";
import {useTranslation} from "react-i18next";

export default function ComfortablePricing() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');

    return (
        <>
            <section className="relative md:py-12 py-8 bg-gray-50 dark:bg-slate-800" id="pricing">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('pricing.title')}</h3>

                        <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">{t('pricing.subtitle')}</p>
                    </div>

                    <div className="flex flex-col gap-4 md:flex-row">
                        <div className="shadow rounded-md p-6 dark:shadow-slate-600 flex-1">
                            <div className="pb-8">
                                <h3 className="mb-6 text-xl font-medium text-slate-800 dark:text-slate-300">{t('pricing.options.team.title')}</h3>
                                <div className="mb-6 text-slate-800 dark:text-slate-300 pb-1">
                                    {lang !== 'pl' && <span
                                        className="relative h6 -top-5 text-xl text-slate-800 dark:text-slate-300">$</span>}
                                    <span
                                        className="text-5xl h6 font-bold text-slate-800 dark:text-slate-300">{t('pricing.options.team.price')}</span>
                                    {lang === 'pl' && <span
                                        className="relative h6 -top-5 ml-2 text-xl text-slate-800 dark:text-slate-300">PLN</span>}
                                    <span
                                        className="inline-block h6 ms-1">/ {t('pricing.options.user')} / {t('pricing.options.month')}</span>
                                </div>
                                <p className="mb-6 text-slate-800 dark:text-slate-300">{t('pricing.options.team.subtitle')}</p>
                                <Link to="/contactus"
                                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor2-700 hover:bg-brandColor2-800 border-brandColor2-700 hover:border-brandColor2-800 text-white rounded-md w-full">
                                    {t('pricing.button')}
                                </Link>
                            </div>
                            <div className="border-b border-slate-800/10"></div>
                            <ul className="self-start pt-8">
                                <li className="flex items-center mb-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.team.features.f1')}</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.team.features.f2')}</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.team.features.f3')}</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.team.features.f4')}</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.team.features.f5')}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="shadow rounded-md p-6 bg-gradient-to-t bg-brandColor-950 flex-1">
                            <div className="pb-8">
                                <h3 className="mb-6 text-xl font-medium text-white">{t('pricing.options.business.title')}</h3>
                                <div className="mb-6 text-white/50 pb-1">
                                    {lang !== 'pl' && <span className="relative h6 -top-5 text-xl">$</span>}
                                    <span
                                        className="text-5xl h6 font-bold text-white">{t('pricing.options.business.price')}</span>
                                    {lang === 'pl' && <span className="relative h6 -top-5 text-xl ml-2">PLN</span>}
                                    <span
                                        className="inline-block h6 ms-1">/ {t('pricing.options.user')} / {t('pricing.options.month')}</span>
                                </div>
                                <p className="mb-6 text-white">{t('pricing.options.business.subtitle')}</p>
                                <Link to="/contactus"
                                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor2-700 hover:bg-brandColor2-800 border-brandColor2-700 hover:border-brandColor2-800 text-white rounded-md w-full">
                                    {t('pricing.button')}
                                </Link>
                            </div>
                            <div className="border-b border-slate-200/10"></div>
                            <ul className="self-start pt-8">
                                <li className="flex items-center mb-1 text-white/80">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span className="font-semibold">{t('pricing.options.business.features.f1')}</span>
                                    <span className="font-bold">&nbsp;+</span>
                                </li>
                                <li className="flex items-center my-1 text-white/80">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.business.features.f2')}</span>
                                </li>
                                <li className="flex items-center my-1 text-white/80">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.business.features.f3')}</span>
                                </li>
                                <li className="flex items-center my-1 text-white/80">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.business.features.f4')}</span>
                                </li>
                                <li className="flex items-center my-1 text-white/80">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.business.features.f5')}</span>
                                </li>
                            </ul>
                        </div>

                        <div className="shadow rounded-md p-6 dark:shadow-slate-600 flex-1">
                            <div className="pb-8">
                                <h3 className="mb-6 text-xl font-medium text-slate-800 dark:text-slate-300">{t('pricing.options.enterprise.title')}</h3>
                                <div className="mb-6 text-white/50">
                                    <p className="inline-block text-slate-800 dark:text-slate-200">{t('pricing.options.enterprise.subtitle')}</p>
                                </div>
                                <p className="mb-6 text-slate-800 dark:text-slate-300">{t('pricing.options.enterprise.subtitle2')}</p>
                                <Link to="/contactus"
                                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor2-700 hover:bg-brandColor2-800 border-brandColor2-700 hover:border-brandColor2-800 text-white rounded-md w-full">
                                    {t('pricing.button2')}
                                </Link>
                            </div>
                            <div className="border-b border-slate-800/10"></div>
                            <ul className="self-start pt-8">
                                <li className="flex items-center mb-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span className="font-semibold">{t('pricing.options.enterprise.features.f1')}</span>
                                    <span className="font-bold">&nbsp;+</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.enterprise.features.f2')}</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.enterprise.features.f3')}</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.enterprise.features.f4')}</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-800/80 dark:text-slate-300">
                                    <i className="uil uil-check-circle text-xl text-brandColor2-600 me-2"></i>
                                    <span>{t('pricing.options.enterprise.features.f5')}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 text-center pt-10">
                        <p className="text-slate-500 dark:text-slate-300 max-w-4xl mx-auto">{t('pricing.trialNote')}</p>
                    </div>
                </div>
            </section>
        </>
    )
}
