import React from 'react'
import OurServices from '../component/our-services';
import MeetOurs from '../component/meet-ours';
import About from '../component/about';
import {Link} from 'react-router-dom';
import Navbar from '../component/navbar';
import Footer from '../component/footer';
import {useTranslation} from "react-i18next";

export default function AboutUs() {
    const [t] = useTranslation('common');

    return (
        <>
            <div className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">
                <Navbar/>
                <section
                    className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-about.webp')] bg-cover bg-center bg-top bg-no-repeat"
                    style={{backgroundPosition: '0 22%'}}
                    id="home">
                    <div className="absolute inset-0 bg-slate-950/80"></div>

                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">{t('about.header')}</h3>
                        </div>
                    </div>
                </section>
                <section className="relative md:py-24 py-16" id="about">
                    <About/>
                    <OurServices/>
                    <MeetOurs/>
                    <div className="container lg:mt-24 mt-16">
                        <div className="grid grid-cols-1 text-center">
                            <h3 className="mb-6 md:text-2xl text-xl font-medium">{t('about.questions')}</h3>

                            <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">{t('about.questionsText')}</p>

                            <div className="mt-6">
                                <Link to="/contactus"
                                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:brandColor-800 text-white rounded-md"><i
                                    className="uil uil-phone align-middle me-2"></i> {t('about.contactUs')}</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </>
    )
}

