import React, {useContext} from 'react'
import {Link} from "react-router-dom";
import {Hexagon} from 'react-feather';
import {useTranslation} from "react-i18next";
import {LanguageContext} from "../App";

export default function ContactData() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');

    const ContactData = [
        {
            icon: 'uil uil-phone',
            title: t('contact.form.phone'),
            detail: <div className="flex flex-col">
                <span>{t('contact.phoneSubtext.days')}</span>
                <span>{t('contact.phoneSubtext.hours')}</span>
                {lang === 'en' && <span>&nbsp;</span>}
            </div>,
            type: t('general.phoneNumber'),
            link: `tel:${t('general.phoneNumber')}`
        },
        {
            icon: 'uil uil-envelope',
            title: t('contact.form.emailAddress'),
            detail: <div className="flex flex-col">
                <span>{t('contact.emailSubtext.l1')}</span>
                <span>{t('contact.emailSubtext.l2')}</span>
                {lang === 'en' && <span>&nbsp;</span>}
            </div>,
            type: t('general.email'),
            link: `mailto:${t('general.email')}`
        },
        {
            icon: 'uil uil-map-marker',
            title: t('contact.form.address'),
            detail: <div className="flex flex-col">
                <span>{t('contact.addressText.street')}</span>
                <span>{t('contact.addressText.office')}</span>
                <span>{t('contact.addressText.town')}</span>
                {lang === 'en' && <span>{t('contact.addressText.country')}</span>}
            </div>,
            type: t('contact.viewOnGoogleMaps'),
            link:'https://maps.app.goo.gl/RduEUuNcpFGP8ftE6'
        }
    ]
    return (
        <>
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                    {ContactData.map((item, index) => (

                        <div className="text-center px-6" key={index}>
                            <div className="relative overflow-hidden text-transparent -m-3">
                                <Hexagon className="h-28 w-28 fill-brandColor-600/5 mx-auto rotate-[30deg]"></Hexagon>
                                <div
                                    className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-brandColor-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                                    <i className={item.icon}></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-xl font-medium">{item.title}</h5>
                                <div className="text-slate-500 dark:text-slate-300 mt-3">{item.detail}</div>

                                <div className="mt-5">
                                    <Link to={item.link} target={item.link.includes('tel') ? '_self' : '_blank'}
                                          className="text-slate-500 dark:text-slate-300 hover:text-brandColor-600 after:bg-violet-600 transition duration-500">{item.type}</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
