import laptop from '../../assets/images/laptop2.webp';
import React, {useEffect, useState} from 'react'
import ComfortablePricing from '../../component/comfortable-pricing';
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import {Link} from "react-router-dom";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import IntranetFunctions from "../../component/intranet-functions";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import intranetMain from "../../assets/images/intranet-carousel/intranet-main.webp";
import intranetAi from "../../assets/images/intranet-carousel/intranet-ai.webp";
import intranetHoliday from "../../assets/images/intranet-carousel/intranet-holiday.webp";
import intranetInfo from "../../assets/images/intranet-carousel/intranet-info.webp";
import intranetModules from "../../assets/images/intranet-carousel/intranet-modules.webp";

export default function Intranet() {
    const [t] = useTranslation('common');
    const [activeImage, setActiveImage] = useState(-1);

    useEffect(() => {
        function onKeyDown(e) {
            if (e.key === 'Escape') {
                setActiveImage(-1);
            }
        }

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, []);

    const slides = [
        {
            image: intranetMain,
            description: t('services.pages.intranet.carousel.main'),
        },
        {
            image: intranetInfo,
            description: t('services.pages.intranet.carousel.info'),
        },
        {
            image: intranetHoliday,
            description: t('services.pages.intranet.carousel.holiday'),
        },
        {
            image: intranetModules,
            description: t('services.pages.intranet.carousel.modules'),
        },
        {
            image: intranetAi,
            description: t('services.pages.intranet.carousel.ai'),
        }
    ]

    return (
        <>
            <link rel="preload" as="image" href={laptop}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 md:h-auto py-0 flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {t('services.pages.intranet.header')} <span
                                    className="font-bold">{t('services.pages.intranet.headerStrong')}</span>
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.intranet.text')}</p>

                                <div className="relative mt-10">
                                    <Link to="/contactus"
                                          className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:border-brandColor-800 text-white rounded-md">
                                        {t('services.pages.intranet.trial')}
                                    </Link>
                                </div>
                            </div>

                            <img src={laptop} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <IntranetFunctions/>
            <ComfortablePricing/>
            <section className="relative md:py-24 py-16 md:pt-0 pt-0 bg-gray-50 dark:bg-slate-800">
                <div className="container">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-1">
                            <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                <div className="lg:col-start-2 lg:col-span-10">
                                    <div className="relative z-50">
                                        <Carousel
                                            style={{zIndex: 51}}
                                            autoPlay
                                            infiniteLoop
                                            showIndicators={true}
                                            showThumbs={false}
                                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                                hasPrev && (
                                                    <button type="button" onClick={onClickHandler} title={label}
                                                            className="absolute top-2/4 start-4 z-50 inline-flex items-center justify-center w-10 h-10 text-white transition-all duration-500 ease-in-out bg-brandColor-900 rounded-full shadow-lg dark:bg-brandColor-900/50 dark:hover:bg-brandColor-900/70 hover:bg-brandColor-900/70">
                                                        <i className="uil uil-angle-left text-xl"></i>
                                                    </button>
                                                )
                                            }
                                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                                hasNext && (
                                                    <button type="button" onClick={onClickHandler} title={label}
                                                            className="absolute top-2/4 end-4 z-50 inline-flex items-center justify-center w-10 h-10 text-white transition-all duration-500 ease-in-out bg-brandColor-900 rounded-full shadow-lg dark:bg-brandColor-900/50 dark:hover:bg-brandColor-900/70 hover:bg-brandColor-900/70">
                                                        <i className="uil uil-angle-right text-xl"></i>
                                                    </button>
                                                )
                                            }
                                            renderIndicator={(onClickHandler, isSelected, index, label) => {
                                                return (
                                                    <li
                                                        style={{
                                                            background: isSelected ? '#900c22' : 'rgba(0,0,0,0.5)',
                                                            width: isSelected ? '2rem' : '1rem',
                                                            border: '1px solid #000',
                                                            marginTop: '8rem'
                                                        }}
                                                        aria-label={`Slide ${index + 1}`}
                                                        title={`${label} ${index + 1}`}
                                                        onClick={onClickHandler}
                                                        key={index}
                                                        className="inline-block mr-3 h-4 cursor-pointer rounded-full border-brandColor-900 hover:border-brandColor-900 hover:bg-brandColor-900 transition-all duration-500 ease-in-out"
                                                    />)
                                            }}
                                            onClickItem={(index) => setActiveImage(index)}
                                        >
                                            {slides.map((slide, index) => (
                                                <div key={index} className="z-50 cursor-pointer">
                                                    <img src={slide.image} alt={slide.description}
                                                         className="w-fit h-auto"
                                                         aria-label={`Screenshot - ${slide.description}`}/>
                                                </div>
                                            ))}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                            <div className="content md:mt-8">
                                <div
                                    className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                    <div className="lg:col-start-2 lg:col-span-10">
                                        <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                                            <div>
                                                <div className="section-title text-md-start">
                                                    <h3 className="text-white/50">{t('index.intranet.title')}</h3>
                                                    <h4 className="md:text-2xl text-xl font-medium text-white mt-2">{t('index.intranet.text.l1')}
                                                        <br/> {t('index.intranet.text.l2')}</h4>
                                                </div>
                                            </div>

                                            <div className="section-title text-md-start">
                                                <p className="text-white/50 max-w-xl mx-auto mb-2">{t('services.pages.intranet.carousel.subtext')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 bg-brandColor-950"></div>
            </section>
            <GetInTouch/>
            <Footer/>
            <div
                className={`${activeImage === -1 ? 'hidden' : 'xl:flex'} fixed p-[5%] top-0 left-0 right-0 bottom-0 flex-col hidden z-[1000]`}>
                <div className="fixed bg-black/50 z-[1001] self-stretch top-0 left-0 right-0 bottom-0"
                     onClick={() => setActiveImage(-1)}></div>
                <div className="bg-slate-200 dark:bg-slate-800 flex flex-col self-center rounded-2xl min-h-0 z-[1500]">
                    <div className="flex flex-row justify-between p-4">
                        <span></span>
                        <span className="text-xl self-center">{slides[activeImage]?.description}</span>
                        <i className="uil uil-times text-3xl cursor-pointer" onClick={() => setActiveImage(-1)}></i>
                    </div>
                    <img src={slides[activeImage]?.image} alt={slides[activeImage]?.description}
                         className="max-h-[80vh] max-w-[80vw] w-auto h-auto rounded-b-2xl"/>
                </div>
            </div>
        </>
    )
}
