import logo_dark from '../assets/images/logo.svg';
import logo_light from '../assets/images/logo-light.svg';
import React, {useContext, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom';
import {LanguageContext} from "../App";
import {useTranslation} from "react-i18next";

export default function Navbar(props) {
    const [isOpen, setMenu] = useState(false);
    const {darkMode} = props;

    const {setLang} = useContext(LanguageContext);
    const [t, i18n] = useTranslation('common');

    const location = useLocation();

    const {pathname} = location;

    const splitLocation = pathname.split("/");

    useEffect(() => {
        function windowScroll() {
            const navbar = document.getElementById("navbar");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("is-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("is-sticky");
                }
            }

            const mybutton = document.getElementById("back-to-top");
            if (mybutton != null) {
                if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                    mybutton.classList.add("flex");
                    mybutton.classList.remove("hidden");
                } else {
                    mybutton.classList.add("hidden");
                    mybutton.classList.remove("flex");
                }
            }
        }

        window.addEventListener("scroll", windowScroll);

        return () => {
            window.removeEventListener("scroll", windowScroll);
        }
    }, []);


    const toggleMenu = () => {
        console.log(isOpen)
        setMenu(!isOpen)
    }

    const updateLanguage = (lang) => {
        setLang(lang);
        localStorage.setItem('retoric-lang', lang);
    }

    return (
        <>
            <nav className="navbar" id="navbar">
                <div className="container flex flex-wrap items-center justify-end">
                    {!darkMode &&
                        <a className="navbar-brand md:me-8" href="/index" aria-label={t('general.accessibility.home')}>
                            <span className="inline-block dark:hidden">
                                <img src={logo_dark} className="l-dark w-[200px] h-auto"
                                     alt={t('general.accessibility.logo')}/>
                                <img src={logo_light} className="l-light w-[200px] h-auto"
                                     alt={t('general.accessibility.logo')}/>
                            </span>
                            <img src={logo_light} className="hidden dark:inline-block w-[200px] h-auto"
                                 alt={t('general.accessibility.logo')}/>
                        </a>
                    }
                    {darkMode &&
                        <a className="navbar-brand md:me-8" href="/index" aria-label={t('general.accessibility.home')}>
                            <img src={logo_dark} className="dark:hidden inline-block w-[200px] h-auto"
                                 alt={t('general.accessibility.logo')}/>
                            <img src={logo_light} className="hidden dark:inline-block w-[200px] h-auto"
                                 alt={t('general.accessibility.logo')}/>
                        </a>
                    }
                    <div className="nav-icons flex items-center lg_992:order-2 ms-auto lg:ms-4">
                        <ul className="list-none menu-social mb-0">
                            <li className="inline">
                                <span className={'nav-link flag cursor-pointer mr-3'}><i
                                    className="fi fis fi-pl flag text-xl"
                                    onClick={() => i18n.changeLanguage('pl').then(() => updateLanguage('pl'))}></i>
                                </span>
                                <span className={'nav-link flag cursor-pointer'}><i
                                    className="fi fis fi-gb flag text-xl"
                                    onClick={() => i18n.changeLanguage('en').then(() => updateLanguage('en'))}></i>
                                </span>
                            </li>
                        </ul>

                        <button data-collapse="menu-collapse" type="button" onClick={toggleMenu}
                                className=" btn collapse-btn inline-flex items-center ms-3 text-slate-950 dark:text-white lg_992:hidden"
                                aria-controls="menu-collapse" aria-expanded="false">
                            <span className="sr-only">Navigation Menu</span>
                            <i className="uil uil-ellipsis-v text-[24px]"></i>
                        </button>
                    </div>
                    <div
                        className={`${!isOpen ? 'navigation lg_992:order-1 lg_992:flex hidden ms-auto' : 'navigation lg_992:order-1 lg_992:flex ms-auto block'}`}
                        id="menu-collapse">

                        <ul className={`navbar-nav sidebar-nav ${darkMode ? '' : 'nav-light'}`} id="navbar-navlist">
                            <li className={`nav-item navbar-item ${splitLocation[1] === '' ? 'active' : ''}`}>
                                <Link to="/" className="nav-link">{t('nav.home')}</Link>
                            </li>
                            <li className={`nav-item navbar-item ${splitLocation[1] === 'services' ? 'active' : ''}`}>
                                <Link to="/services" className="nav-link">{t('nav.services')}</Link>
                            </li>
                            <li className={`nav-item navbar-item ${splitLocation[1] === 'aboutus' ? 'active' : ''}`}>
                                <Link to="/aboutus" className="nav-link">{t('nav.about')}</Link>
                            </li>
                            <li className={`nav-item navbar-item ${splitLocation[1] === 'blogs' ? 'active' : ''}`}>
                                <Link to="/blogs" className="nav-link">{t('nav.blog')}</Link>
                            </li>
                            <li className={`nav-item navbar-item ${splitLocation[1] === 'careers' ? 'active' : ''}`}>
                                <Link to="/careers" className="nav-link">{t('nav.careers')}</Link>
                            </li>
                            <li className={`nav-item navbar-item ${splitLocation[1] === 'contactus' ? 'active' : ''}`}>
                                <Link to="/contactus" className="nav-link">{t('nav.contact')}</Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>

        </>
    )
}


