import React from 'react'
import kasia from '../assets/images/team/kasia.webp';
import igor from '../assets/images/team/igor3.webp';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const MeetOursData = [
    // {
    //     id: 1,
    //     image: kasia,
    //     title: 'Katarzyna Topolska',
    //     type: 'Chief Executive Officer',
    //     linkedin: 'https://www.linkedin.com/in/katarzyna-topolska/'
    // },
    {
        id: 2,
        image: igor,
        title: 'Igor Topolski',
        type: 'Chief Executive Officer',
        linkedin: 'https://www.linkedin.com/in/igor-topolski/',
        github: 'https://www.github.com/igor-topolski'
    },
]

export default function MeetOurs() {
    const [t] = useTranslation('common');

    const AltMap = {
        1: t('general.accessibility.alt.kasiaPortrait'),
        2: t('general.accessibility.alt.igorPortrait'),
        3: t('general.accessibility.alt.krzysiekPortrait'),
    }

    return (
        <>
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 text-center">
                    <h3 className="md:text-2xl text-xl font-medium">{t('about.meetTeam')}</h3>
                    {/*<p className="text-slate-400 max-w-xl mx-auto">This is just a simple text made for this unique and awesome*/}
                    {/*    template, you can replace it with any text.</p>*/}
                </div>
                <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
                    {MeetOursData.map((item, index) => (
                        <div className="lg:col-span-12 md:col-span-12" key={index}>
                            <div className="group text-center">
                                <div
                                    className={`relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden shadow-xl shadow-slate-500/20`}>
                                    <img src={item.image} className="w-fit h-auto" alt={AltMap[item.id]}/>

                                    <ul
                                        className="list-none absolute top-1/2 -translate-y-1/2 -end-20 group-hover:end-5 transition-all duration-500 ease-in-out">
                                        <li className="mt-1"><Link to={item.linkedin} target={"_blank"}
                                                                   aria-label={`${t('general.accessibility.linkedInLink')}${item.title}`}
                                                                   className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-linkedin hover:bg-black border-violet-600 hover:border-violet-700 text-white"><i
                                            className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                                    </ul>
                                </div>

                                <div className="content mt-3">
                                    <Link to={item.linkedin} target={"_blank"} referrerPolicy="no-referrer"
                                          className="text-lg hover:text-brandColor-700 transition-all duration-500 ease-in-out h5">{item.title}</Link>
                                    <p className="text-slate-500 dark:text-slate-300">{item.type}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
