import React from 'react'
import Navbar from '../../component/navbar'
import {Link} from 'react-router-dom';
import Footer from '../../component/footer';
import Detail from '../../component/detail'
import {useTranslation} from "react-i18next";

export default function Services() {
    const [t] = useTranslation('common');

    return (
        <>
            <Navbar/>
            <section
                className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-services.webp')] bg-top bg-no-repeat bg-cover"
                id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">{t('about.services.title')}</h3>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800">
                <div className="container lg mx-auto">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        <Detail/>
                    </div>
                </div>
                {/*<ClientSreview />*/}
                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-6 md:text-2xl text-xl font-medium">{t('about.questions')}</h3>

                        <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">{t('about.questionsText')}</p>

                        <div className="mt-6">
                            <Link to="/contactus"
                                  className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:border-brandColor-800 text-white rounded-md"><i
                                className="uil uil-phone align-middle me-2"></i> {t('about.contactUs')}</Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>

        </>
    )
}
