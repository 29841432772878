import React from 'react'
import about from '../assets/images/about-us2.webp';
import {useTranslation} from "react-i18next";

export default function About() {
    const [t] = useTranslation('common');

    return (
        <>
            <section className="relative md:py-24 py-16" id="about">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <img src={about} className="rounded-lg relative w-fit h-auto"
                                     alt={t('general.accessibility.alt.about')}/>
                            </div>
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-7">
                                <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('about.subheader')}</h3>

                                <div className="flex flex-col" style={{gap: '16px'}}>
                                    <p className="text-slate-500 dark:text-slate-300 max-w-2xl mx-auto"
                                       style={{textAlign: 'justify'}}>{t('about.aboutCompany.p1')}</p>
                                    <p className="text-slate-500 dark:text-slate-300 max-w-2xl mx-auto"
                                       style={{textAlign: 'justify'}}>{t('about.aboutCompany.p2')}</p>
                                    <p className="text-slate-500 dark:text-slate-300 max-w-2xl mx-auto"
                                       style={{textAlign: 'justify'}}>{t('about.aboutCompany.p3')}</p>
                                    <p className="text-slate-500 dark:text-slate-300 max-w-2xl mx-auto"
                                       style={{textAlign: 'justify'}}>{t('about.aboutCompany.p4')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};
