import React, {useContext} from 'react'
import {Link} from "react-router-dom";
import Navbar from '../component/navbar';
import Footer from '../component/footer';
import {LanguageContext} from "../App";
import {useTranslation} from "react-i18next";
import MeetOurs from "../component/meet-ours";
import about from "../assets/images/about-us2.webp";
import remote from "../assets/images/remote-first.webp";

export default function Careers() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');

    return (
        <>
            <div className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">

                <Navbar/>

                <section
                    className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-careers.webp')] bg-cover bg-center bg-top bg-no-repeat"
                    style={{backgroundPosition: '0 32%'}}
                    id="home">
                    <div className="absolute inset-0 bg-slate-950/80"></div>

                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">{t('careers.title')}</h3>
                        </div>
                    </div>
                </section>
                <section className="relative md:py-12 py-16" id="about">
                    <div className="container mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                            <div className="lg:col-span-5 block lg:hidden">
                                <div className="relative">
                                    <img src={about} className="rounded-lg relative w-fit h-auto"
                                         alt={t('general.accessibility.alt.about')}/>
                                </div>
                            </div>
                            <div className="lg:col-span-7">
                                <div className="lg:ms-7">
                                    <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('careers.subheader')}</h3>

                                    <div className="flex flex-col" style={{gap: '16px'}}>
                                        <p className="text-slate-500 dark:text-slate-300 max-w-2xl mx-auto"
                                           style={{textAlign: 'justify'}}>{t('careers.text')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-span-5 hidden lg:block">
                                <div className="relative">
                                    <img src={about} className="rounded-lg relative w-fit h-auto"
                                         alt={t('general.accessibility.alt.about')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="relative md:py-12 py-8" id="remote-first">
                    <div className="container mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                            <div className="lg:col-span-5">
                                <div className="relative">
                                    <img src={remote} className="rounded-lg relative w-fit h-auto"
                                         alt={t('general.accessibility.alt.remote')}/>
                                </div>
                            </div>
                            <div className="lg:col-span-7">
                                <div className="lg:ms-7">
                                    <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('careers.subheader2')}</h3>

                                    <div className="flex flex-col" style={{gap: '16px'}}>
                                        <p className="text-slate-500 dark:text-slate-300 max-w-2xl mx-auto"
                                           style={{textAlign: 'justify'}}>{t('careers.text2')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <MeetOurs/>
                <section className="relative md:py-12 py-8" id="remote-first">
                    <div className="container mx-auto">
                        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                            <div className="lg:col-span-5">
                                <div className="grid grid-cols-1 pb-8 text-justify">
                                    <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('careers.perks.title')}</h3>
                                    <p className="text-slate-500 dark:text-slate-300 max-w-3xl mx-auto">{t('careers.perks.subheader')}</p>
                                </div>
                            </div>
                            <div className="lg:col-span-7">
                                <ul className="self-start pt-8">
                                    <li className="flex items-center mb-1 text-slate-600 dark:text-slate-300">
                                        <i className="uil uil-check-circle text-xl text-green-600 me-2"></i>
                                        <span>{t('careers.perks.p1')}</span>
                                    </li>
                                    <li className="flex items-center mb-1 text-slate-600 dark:text-slate-300">
                                        <i className="uil uil-check-circle text-xl text-green-600 me-2"></i>
                                        <span>{t('careers.perks.p2')}</span>
                                    </li>
                                    <li className="flex items-center mb-1 text-slate-600 dark:text-slate-300">
                                        <i className="uil uil-check-circle text-xl text-green-600 me-2"></i>
                                        <span>{t('careers.perks.p3')}</span>
                                    </li>
                                    {/*<li className="flex items-center mb-1 text-slate-600 dark:text-slate-300">*/}
                                    {/*    <i className="uil uil-check-circle text-xl text-green-600 me-2"></i>*/}
                                    {/*    <span>{t('careers.perks.p4')}</span>*/}
                                    {/*</li>*/}
                                    <li className="flex items-center mb-1 text-slate-600 dark:text-slate-300">
                                        <i className="uil uil-check-circle text-xl text-green-600 me-2"></i>
                                        <span>{t('careers.perks.p5')}</span>
                                    </li>
                                    <li className="flex items-center mb-1 text-slate-600 dark:text-slate-300">
                                        <i className="uil uil-check-circle text-xl text-green-600 me-2"></i>
                                        <span>{t('careers.perks.p6')}</span>
                                    </li>
                                    <li className="flex items-center mb-1 text-slate-600 dark:text-slate-300">
                                        <i className="uil uil-check-circle text-xl text-green-600 me-2"></i>
                                        <span>{t('careers.perks.p7')}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('careers.subheader3')}</h3>
                        <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">{t('careers.text3')}</p>
                    </div>
                    <div className="grid md:grid-cols-4 grid-cols-1 gap-[30px] items-center">
                        <div
                            className={"p-6 shadow-2xl rounded-2xl shadow-brandColor-500" + (lang === 'en' ? " md:mt-6" : " md:mt-24")}>
                            <div className="pb-2">
                                <div className="mb-6 dark:text-white/50">
                                    <span className="text-5xl h6">1. </span>
                                    <span
                                        className="text-xl h6 font-medium dark:text-white">{t('careers.cards.app.title')}</span>
                                </div>
                                <p className="text-slate-500 dark:text-slate-300 text-center">{t('careers.cards.app.text')}</p>
                            </div>
                        </div>

                        <div
                            className={"p-6 shadow-2xl rounded-2xl rounded-2xl shadow-brandColor3-500" + (lang === 'en' ? " md:mt-32" : "")}>
                            <div className="pb-2">
                                <div className="mb-6 dark:text-white/50">
                                    <span className="text-5xl h6">2. </span>
                                    <span
                                        className="text-xl h6 font-medium dark:text-white">{t('careers.cards.int1.title')}</span>
                                </div>
                                <p className="text-slate-500 dark:text-slate-300 text-center">{t('careers.cards.int1.text')}</p>
                            </div>
                        </div>

                        <div className="p-6 shadow-2xl rounded-2xl rounded-2xl shadow-brandColor2-500">
                            <div className="pb-2">
                                <div className="mb-6 dark:text-white/50">
                                    <span className="text-5xl h6">3. </span>
                                    <span
                                        className="text-xl h6 font-medium dark:text-white">{t('careers.cards.test.title')}</span>
                                </div>
                                <p className="text-slate-500 dark:text-slate-300 text-center">{t('careers.cards.test.text')}</p>
                            </div>
                        </div>

                        <div className="p-6 shadow-2xl rounded-2xl rounded-2xl shadow-brandColor4-500">
                            <div className="pb-2">
                                <div className="mb-6 dark:text-white/50">
                                    <span className="text-5xl h6">4. </span>
                                    <span
                                        className="text-xl h6 font-medium dark:text-white">{t('careers.cards.final.title')}</span>
                                </div>
                                <p className="text-slate-500 dark:text-slate-300 text-center">{t('careers.cards.final.text')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container lg:my-24 my-16">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-6 md:text-2xl text-xl font-medium">{t('careers.questions')}</h3>

                        <p className="text-slate-500 dark:text-slate-300 max-w-3xl mx-auto">{t('careers.questionsText')}</p>

                        <div className="mt-6">
                            <Link to="/contactus"
                                  className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:brandColor-800 text-white rounded-md"><i
                                className="uil uil-phone align-middle me-2"></i> {t('about.contactUs')}</Link>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        </>
    )
}
