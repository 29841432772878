import laptop from '../assets/images/laptop2.webp';
import React, {useContext, useState} from 'react'
import About from '../component/about'
import OurServices from '../component/our-services'
import ComfortablePricing from '../component/comfortable-pricing';
import BlogsNews from '../component/blogs-news';
import GetInTouch from '../component/get-in-touch';
import Footer from '../component/footer';
import {Link} from "react-router-dom";
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../component/navbar";
import {useTranslation} from "react-i18next";
import {LanguageContext} from "../App";
import spinner from "../assets/images/spinner.svg";

export const BACKEND = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:5100' : 'https://api.retoric.pl';

export default function Index() {
    const {lang} = useContext(LanguageContext);
    const [isOpen, setOpen] = useState(false)
    const [t] = useTranslation('common');
    const [formError, setFormError] = useState('')
    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    const handleErrors = (err) => {
        if (err.error === 'name-missing') {
            setFormError(t('index.header.form.name.error.required'));
            return false;
        } else if (err.error === 'email-disposable') {
            setFormError(t('index.header.form.email.error.disposable'));
            return false;
        } else if (err.error === 'email-missing') {
            setFormError(t('index.header.form.email.error.required'));
            return false;
        } else if (err.error.startsWith('email')) {
            setFormError(t('index.header.form.email.error.invalid'));
            return false;
        } else if (err.error === 'phoneNumber-invalid') {
            setFormError(t('index.header.form.phoneNumber.error.invalid'));
            return false;
        } else if (err.error.startsWith('phoneNumber')) {
            setFormError(t('index.header.form.phoneNumber.error.required'));
            return false;
        }

        setFormError('');
        return true;
    }

    const submitForm = (e) => {
        e.preventDefault();
        setSent(false);

        const message = {
            name: e.target.name.value,
            company: e.target.company.value,
            email: e.target.email.value,
            phoneNumber: e.target.number.value,
            lang: lang
        }

        if (!message.name) {
            setFormError(t('index.header.form.name.error.required'));
            return;
        }

        if (!message.email) {
            setFormError(t('index.header.form.email.error.required'));
            return;
        }

        if (!message.phoneNumber) {
            setFormError(t('index.header.form.phoneNumber.error.required'));
            return;
        }

        setFormError('');
        setSending(true);

        fetch(`${BACKEND}/demo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(message)
        })
            .then(res => res.json())
            .then(data => {
                setSending(false);

                if (data.success) {
                    setSent(true);
                    e.target.name.value = '';
                    e.target.company.value = '';
                    e.target.email.value = '';
                    e.target.number.value = '';

                    return;
                }

                if (!handleErrors(data))
                    return;

                setSent(true);
            })
            .catch(err => {
                handleErrors(err);
                setFormError(t('contact.form.error'));
                setSending(false);
                setSent(false);
            });
    }

    return (
        <>
            <Navbar/>
            <section
                className="py-36 lg:py-48 w-full table relative bg-[url('../../assets/images/bg/bg-3-3.webp')] bg-center bg-no-repeat"
                id="home">
                <div className="absolute inset-0 bg-slate-950 opacity-80"></div>
                <div className="container relative">
                    <div className="grid lg:grid-cols-12 mt-10 md:grid-cols-2 gap-[30px] items-center">
                        <div className="lg:col-span-7">
                            <h4 className="text-white lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">{t('index.header.title')}
                                <br/> {t('index.header.title2')}</h4>

                            <p className="text-white opacity-75 mb-0 max-w-2xl text-lg">{t('index.header.text')}</p>

                            <div className="relative mt-10 flex flex-row justify-between">
                                <Link to="/services/intranet"
                                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:border-brandColor-800 text-white rounded-md me-1">{t('index.header.moreInfo')}</Link>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="wQuIIMPouN4"
                                            onClose={() => setOpen(false)}/>
                                <div>
                                    <Link to="#" onClick={() => setOpen(true)}
                                          aria-label={t('general.accessiblity.watchVideo')}
                                          className="h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-brandColor-900 hover:bg-brandColor-800 hover:text-slate-950 border-brandColor-900 hover:border-brandColor-800 text-white lightbox"><i
                                        className="ui uil-play text-xl align-middle pl-0.5"></i></Link>
                                    <small
                                        className="text-white text-sm uppercase align-middle ms-2">{t('index.header.watchVideo')}</small>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-5">
                            <div
                                className="bg-white dark:bg-slate-900 rounded-xl shadow md:p-10 p-6 lg:ms-10 z-10 relative">
                                <h4 className="mb-5 font-medium text-2xl dark:text-white">{t('index.header.form.title')}
                                    <br/> {t('index.header.form.title2')}</h4>

                                <form method="POST" onSubmit={(e) => submitForm(e)}>
                                    <div className="grid grid-cols-1">
                                        <div className="mb-4">
                                            <label
                                                className="dark:text-white font-medium">{t('index.header.form.name.label')}<span
                                                className="text-bold text-brandColor-600">*</span></label>
                                            <input name="name" id="fName" type="text"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                   placeholder={t('index.header.form.name.placeholder')}/>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="dark:text-white font-medium">{t('index.header.form.company.label')}</label>
                                            <input name="company" id="cName" type="text"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                   placeholder={t('index.header.form.company.placeholder')}/>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="dark:text-white font-medium">{t('index.header.form.email.label')}<span
                                                className="text-bold text-brandColor-600">*</span></label>
                                            <input name="email" id="yEmail" type="email"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                   placeholder={t('index.header.form.email.placeholder')}/>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="dark:text-white font-medium">{t('index.header.form.phoneNumber.label')}<span
                                                className="text-bold text-brandColor-600">*</span></label>
                                            <input name="number" id="phNumber"
                                                   className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-brandColor-900/50 dark:focus:border-brandColor-200/50 focus:ring-0 mt-2"
                                                   placeholder={t('index.header.form.phoneNumber.placeholder')}/>
                                        </div>
                                        <button type="submit" disabled={sending}
                                                className="py-2 mb-3 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:border-brandColor-800 text-white rounded-md w-full">
                                            {sending ? <img src={spinner} alt="Spinner"
                                                            className="mx-auto"/> : t('index.header.button')}
                                        </button>
                                        <div>
                                            <span
                                                className={`${sent ? 'text-green-700 dark:text-green-400' : 'text-red-700 dark:text-red-400'}`}>{formError ? formError : sent ? t('index.header.form.sent') : ''}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <About/>
            <OurServices/>
            <section className="relative md:py-24 py-16 md:pt-0 pt-0 bg-gray-50 dark:bg-slate-800">
                <div className="container">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-1">
                            <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                <div className="lg:col-start-2 lg:col-span-10">
                                    <div className="relative">
                                        <img src={laptop} className="rounded-md w-fit h-auto"
                                             alt={t('general.accessibility.alt.laptop')}/>
                                        <div className="absolute bottom-2/4 translate-y-2/4 end-0 start-0 text-center">
                                            <Link to="#" onClick={() => setOpen(true)} data-type="youtube"
                                                  aria-label={t('general.accessiblity.watchVideo')}
                                                  data-id="yba7hPeTSjk"
                                                  className="lightbox lg:h-20 h-16 lg:w-20 w-16 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white hover:bg-brandColor-900 text-brandColor-950 hover:text-white duration-500 ease-in-out mx-auto">
                                                <i className="uil uil-play inline-flex items-center justify-center text-3xl"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content md:mt-8">
                                <div
                                    className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                    <div className="lg:col-start-2 lg:col-span-10">
                                        <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                                            <div>
                                                <div className="section-title text-md-start">
                                                    <h3 className="text-white/50">{t('index.intranet.title')}</h3>
                                                    <h4 className="md:text-2xl text-xl font-medium text-white mt-2">{t('index.intranet.text.l1')}
                                                        <br/> {t('index.intranet.text.l2')}</h4>
                                                </div>
                                            </div>

                                            <div className="section-title text-md-start">
                                                <p className="text-white/50 max-w-xl mx-auto mb-2">{t('index.intranet.subtitle')}</p>
                                                <Link to="/services/intranet"
                                                      className="text-white hover:text-brandColor-50">{t('general.moreInfoIntranet')}
                                                    <i
                                                        className="uil uil-angle-end-b align-middle"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 bg-brandColor-950"></div>
            </section>
            <ComfortablePricing/>
            {/*<ClientSreview />*/}
            <BlogsNews/>
            <GetInTouch/>
            <Footer/>
        </>
    )
};
