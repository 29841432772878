import header from '../../assets/images/services/infrastructure/infrastructure.webp';
import cloud from '../../assets/images/services/infrastructure/cloud.webp';
import onSite from '../../assets/images/services/infrastructure/onSite.webp';
import React from 'react'
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Option from "../../component/web-development/option";
import Technologies from "../../component/technologies";

import mongodb from "../../assets/images/services/web-development/mongodb.svg";
import mysql from "../../assets/images/services/web-development/mysql.svg";
import postgresql from "../../assets/images/services/web-development/postgresql.svg";
import kubernetes from "../../assets/images/services/infrastructure/kubernetes.svg";
import docker from "../../assets/images/services/infrastructure/docker.svg";
import aws from "../../assets/images/services/infrastructure/aws.svg";
import azure from "../../assets/images/services/infrastructure/azure.svg";
import gcp from "../../assets/images/services/infrastructure/gcp.svg";
import linux from "../../assets/images/services/infrastructure/linux.svg";
import windows from "../../assets/images/services/infrastructure/windows.svg";
import github from "../../assets/images/services/infrastructure/github.svg";
import terraform from "../../assets/images/services/infrastructure/terraform.svg";
import RelatedServices from "../../component/related-services";


const technologies = [
    {
        name: 'Kubernetes',
        image: kubernetes
    },
    {
        name: 'Docker',
        image: docker
    },
    {
        name: 'AWS',
        image: aws
    },
    {
        name: 'Azure',
        image: azure
    },
    {
        name: 'Google Cloud Platform',
        image: gcp
    },
    {
        name: 'Linux',
        image: linux
    },
    {
        name: 'Windows',
        image: windows
    },
    {
        name: 'GitHub',
        image: github
    },
    {
        name: 'Terraform',
        image: terraform
    },
    {
        name: 'MongoDB',
        image: mongodb
    },
    {
        name: 'MySQL',
        image: mysql
    },
    {
        name: 'PostgreSQL',
        image: postgresql
    },
];

export default function InfrastructureManagement() {
    const [t] = useTranslation('common');

    const services = [
        {
            icon: 'lightbulb-alt',
            title: t('about.services.consulting.title'),
            description: t('about.services.consulting.text'),
            link: "/services/consulting"
        },
        {
            icon: 'graduation-cap',
            title: t('about.services.training.title'),
            description: t('about.services.training.text'),
            link: "/services/training"
        },
        {
            icon: 'document-info',
            title: t('about.services.audit.title'),
            description: t('about.services.audit.text'),
            link: "/services/audit"
        }
    ];

    return (
        <>
            <link rel="preload" as="image" href={header}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 py-0 md:h-auto flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {t('services.pages.infrastructure.header')} <span
                                    className="font-bold">{t('services.pages.infrastructure.headerStrong')}</span>
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.infrastructure.text')}</p>
                            </div>

                            <img src={header} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto mx-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <Technologies technologies={technologies}/>
            <Option title={t('services.pages.infrastructure.services.cloud.title')}
                    image={cloud}
                    imageAlt={t('services.pages.infrastructure.services.cloud.imageAlt')}
                    textLines={[
                        t('services.pages.infrastructure.services.cloud.text.1'),
                        t('services.pages.infrastructure.services.cloud.text.2'),
                        t('services.pages.infrastructure.services.cloud.text.3'),
                        t('services.pages.infrastructure.services.cloud.text.4')
                    ]}
                    orientation={"right"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <Option title={t('services.pages.infrastructure.services.onSite.title')}
                    image={onSite}
                    imageAlt={t('services.pages.infrastructure.services.onSite.imageAlt')}
                    textLines={[
                        t('services.pages.infrastructure.services.onSite.text.1'),
                        t('services.pages.infrastructure.services.onSite.text.2'),
                        t('services.pages.infrastructure.services.onSite.text.3'),
                        t('services.pages.infrastructure.services.onSite.text.4'),
                        t('services.pages.infrastructure.services.onSite.text.5')
                    ]}
                    orientation={"left"}
                    textClassName="dark:text-slate-100"
            />
            <RelatedServices services={services} subtitle={t('services.relatedServices.subtitles.infrastructure')}/>
            <GetInTouch/>
            <Footer/>
        </>
    )
}
