import React, {useContext, useEffect, useState} from 'react'
import Navbar from '../component/navbar'
import Footer from '../component/footer';
import {Link, useParams} from "react-router-dom";
import {GitHub, Linkedin,} from 'react-feather';
import {FormatBlogDate, NewsData, ProcessBlogTitle} from "../component/blogs-news";
import {LanguageContext} from "../App";
import {useTranslation} from "react-i18next";
import {MeetOursData} from "../component/meet-ours";

export default function BlogDetail() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');
    const {title} = useParams();
    const [post, setPost] = useState({});
    const [author, setAuthor] = useState({});

    const AltMap = {
        1: t('general.accessibility.alt.kasiaPortrait'),
        2: t('general.accessibility.alt.igorPortrait'),
        3: t('general.accessibility.alt.krzysiekPortrait'),
    }

    useEffect(() => {
        const processedTitle = ProcessBlogTitle(title);
        const currentPost = NewsData[lang].find((x) => ProcessBlogTitle(x.title) === processedTitle || ProcessBlogTitle(x.titleAlt) === processedTitle);

        setPost(currentPost);
        setAuthor(MeetOursData.find((x) => x.id === currentPost.author));

        return () => null;
    }, [title, lang]);

    return (
        <>
            <Navbar/>
            <section
                className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat"
                id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">{post.title}</h3>

                        <ul className="list-none mt-6">
                            <li className="inline-block text-white/50 mx-5"><span
                                className="text-white block">{t('news.details.published')}</span> <span
                                className="block">{post.date ? FormatBlogDate(post.date, lang) : ''}</span></li>
                            <li className="inline-block text-white/50 mx-5"><span
                                className="text-white block">{t('news.details.readTime')}</span> <span
                                className="block">{post.readTime} min</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800">

                                <img src={post.imageXl} className="rounded-md w-fit h-auto" alt={post.alt}/>

                                <div className="mt-6">
                                    <div dangerouslySetInnerHTML={{__html: post.content}}></div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="sticky top-20">
                                <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">{t('news.details.author')}</h5>
                                <div className="text-center mt-8">
                                    <div
                                        className={`block h-52 w-52 rounded-full overflow-hidden shadow-xl shadow-slate-500/20 mx-auto mb-4`}>
                                        <img src={author.image} className="w-fit h-auto" alt={AltMap[author.id]}/>
                                    </div>

                                    <Link to=""
                                          className="text-lg font-medium hover:text-brandColor-600 transition-all duration-500 ease-in-out h5">{author.title}</Link>
                                    <p className="text-slate-400">{author.type}</p>
                                </div>

                                <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">{t('news.details.social')}</h5>
                                <ul className="list-none text-center mt-8">
                                    {author.linkedin &&
                                        <li className="inline ms-1"><Link to={author.linkedin} target={"_blank"}
                                                                          className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-violet-600 hover:text-white hover:bg-linkedin"><Linkedin
                                            className="h-4 w-4"></Linkedin></Link></li>}
                                    {author.github &&
                                        <li className="inline ms-1"><Link to={author.github} target={"_blank"}
                                                                          className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-violet-600 hover:text-white hover:bg-black"><GitHub
                                            className="h-4 w-4"></GitHub></Link></li>}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<RelatedBlogs/>*/}
                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-6 md:text-2xl text-xl font-medium">{t('about.questions')}</h3>

                        <p className="text-slate-500 dark:text-slate-300 max-w-xl mx-auto">{t('about.questionsText')}</p>

                        <div className="mt-6">
                            <Link to="/contactus"
                                  className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:brandColor-800 text-white rounded-md"><i
                                className="uil uil-phone align-middle me-2"></i> {t('about.contactUs')}</Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}
