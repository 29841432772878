import React, {useEffect, useState} from 'react'

export default function Option({orientation, image, imageAlt, title, textLines, className, textClassName}) {
    const [or, setOr] = useState('left');

    useEffect(() => {
        if (orientation)
            setOr(orientation)

        return () => null;
    }, [orientation]);

    return (
        <>
            <section className={`relative md:py-12 py-16 ${className}`} id="about">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                        <div className={`lg:col-span-5 ${or === 'right' ? 'lg:hidden' : ''}`}>
                            <div className="relative">
                                <img src={image} className="rounded-lg relative w-fit h-auto" alt={imageAlt}/>
                            </div>
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-7">
                                <h3 className={`mb-4 md:text-2xl text-xl font-medium ${textClassName ? textClassName : 'text-slate-500 dark:text-slate-300'}}`}>{title}</h3>

                                <div className="flex flex-col" style={{gap: '16px'}}>
                                    {textLines.map((text, index) => (
                                        <p className={`max-w-2xl mx-auto ${textClassName ? textClassName : 'text-slate-500 dark:text-slate-300'}}`}
                                           style={{textAlign: 'justify'}} key={index}>{text}</p>)
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={`lg:col-span-5 hidden ${or === 'left' ? 'lg:hidden' : 'lg:block'}`}>
                            <div className="relative">
                                <img src={image} className="rounded-lg relative w-fit h-auto" alt={imageAlt}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};
