import header from '../../assets/images/services/web-development/header2.webp';
import webApps from '../../assets/images/services/web-development/web-applications.webp';
import desktopApp from '../../assets/images/services/web-development/desktop-app.webp';
import React from 'react'
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Option from "../../component/web-development/option";
import Technologies from "../../component/technologies";

import html5 from "../../assets/images/services/web-development/html5.svg";
import css from "../../assets/images/services/web-development/css.svg";
import javascript from "../../assets/images/services/web-development/javascript.svg";
import python from "../../assets/images/services/web-development/python.svg";
import react from "../../assets/images/services/web-development/react.svg";
import angular from "../../assets/images/services/web-development/angular.svg";
import storybook from "../../assets/images/services/web-development/storybook.svg";
import tailwind from "../../assets/images/services/web-development/tailwind.svg";
import bootstrap from "../../assets/images/services/web-development/bootstrap.svg";
import typescript from "../../assets/images/services/web-development/typescript.svg";
import dotnet from "../../assets/images/services/web-development/dotnet.svg";
import nodejs from "../../assets/images/services/web-development/nodejs.svg";
import mongodb from "../../assets/images/services/web-development/mongodb.svg";
import mysql from "../../assets/images/services/web-development/mysql.svg";
import postgresql from "../../assets/images/services/web-development/postgresql.svg";
import RelatedServices from "../../component/related-services";

const technologies = [
    {
        name: 'HTML5',
        image: html5
    },
    {
        name: 'CSS',
        image: css
    },
    {
        name: 'JavaScript',
        image: javascript
    },
    {
        name: 'Python',
        image: python
    },
    {
        name: 'React',
        image: react
    },
    {
        name: 'Angular',
        image: angular
    },
    {
        name: 'Storybook',
        image: storybook
    },
    {
        name: 'Tailwind CSS',
        image: tailwind
    },
    {
        name: 'Bootstrap',
        image: bootstrap
    },
    {
        name: 'TypeScript',
        image: typescript
    },
    {
        name: '.NET',
        image: dotnet
    },
    {
        name: 'Node.js',
        image: nodejs
    },
    {
        name: 'MongoDB',
        image: mongodb
    },
    {
        name: 'MySQL',
        image: mysql
    },
    {
        name: 'PostgreSQL',
        image: postgresql
    },
];

export default function WebDevelopment() {
    const [t] = useTranslation('common');

    const services = [
        {
            icon: 'mobile-android',
            title: t('about.services.mobileDevelopment.title'),
            description: t('about.services.mobileDevelopment.text'),
            link: "/services/mobile-applications"
        },
        {
            icon: 'robot',
            title: t('about.services.ai.title'),
            description: t('about.services.ai.text'),
            link: "/services/artificial-intelligence"
        },
        {
            icon: 'server-network-alt',
            title: t('about.services.infrastructure.title'),
            description: t('about.services.infrastructure.text'),
            link: "/services/infrastructure-management"
        }
    ];

    return (
        <>
            <link rel="preload" as="image" href={header}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 py-0 md:h-auto flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {t('services.pages.webDevelopment.header')} <span
                                    className="font-bold">{t('services.pages.webDevelopment.headerStrong')}</span>
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.webDevelopment.text')}</p>
                            </div>

                            <img src={header} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <Technologies technologies={technologies}/>
            <Option title={t('services.pages.webDevelopment.services.web.title')}
                    image={webApps}
                    imageAlt={t('services.pages.webDevelopment.services.web.imageAlt')}
                    textLines={[
                        t('services.pages.webDevelopment.services.web.text.1'),
                        t('services.pages.webDevelopment.services.web.text.2'),
                        t('services.pages.webDevelopment.services.web.text.3'),
                        t('services.pages.webDevelopment.services.web.text.4'),
                        t('services.pages.webDevelopment.services.web.text.5')
                    ]}
                    orientation={"right"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <Option title={t('services.pages.webDevelopment.services.desktop.title')}
                    image={desktopApp}
                    imageAlt={t('services.pages.webDevelopment.services.desktop.imageAlt')}
                    textLines={[
                        t('services.pages.webDevelopment.services.desktop.text.1'),
                        t('services.pages.webDevelopment.services.desktop.text.2'),
                        t('services.pages.webDevelopment.services.desktop.text.3'),
                        t('services.pages.webDevelopment.services.desktop.text.4')
                    ]}
                    orientation={"left"}
                    textClassName="dark:text-slate-100"
            />
            <RelatedServices services={services} subtitle={t('services.relatedServices.subtitles.generic')}/>
            <GetInTouch/>
            <Footer/>
        </>
    )
}
