import header from '../../assets/images/services/consulting/consulting.webp';
import software from '../../assets/images/services/consulting/software.webp';
import ai from '../../assets/images/services/consulting/ai.webp';
import onSite from '../../assets/images/services/infrastructure/onSite.webp';
import general from '../../assets/images/services/consulting/general.webp';
import React from 'react'
import GetInTouch from '../../component/get-in-touch';
import Footer from '../../component/footer';
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Navbar from "../../component/navbar";
import {useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Option from "../../component/web-development/option";
import RelatedServices from "../../component/related-services";


export default function Consulting() {
    const [t] = useTranslation('common');

    const services = [
        {
            icon: 'monitor',
            title: t('about.services.webDevelopment.title'),
            description: t('about.services.webDevelopment.text'),
            link: "/services/web-applications"
        },
        {
            icon: 'robot',
            title: t('about.services.ai.title'),
            description: t('about.services.ai.text'),
            link: "/services/artificial-intelligence"
        },
        {
            icon: 'server-network-alt',
            title: t('about.services.infrastructure.title'),
            description: t('about.services.infrastructure.text'),
            link: "/services/infrastructure-management"
        }
    ];

    return (
        <>
            <link rel="preload" as="image" href={header}/>
            <Navbar darkMode/>
            <section
                className="lg:py-18 py-0 md:h-auto flex items-center relative bg-brandColor-950/10 dark:bg-brandColor-950/50"
                id="home">
                <div className="container relative z-2">
                    <div className="grid grid-cols-1 text-center mt-0 md:mt-12 pt-0 md:pt-12">
                        <div className="mt-28">
                            <div>
                                <h4 className="lg:text-[40px] text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative dark:text-white">
                                    {t('services.pages.consulting.preheader')}
                                    <span
                                        className="font-bold">{t('services.pages.consulting.header')}</span> {t('services.pages.consulting.headerStrong')}
                                </h4>

                                <p className="text-slate-600 dark:text-slate-300 mb-0 max-w-3xl text-lg mx-auto">{t('services.pages.consulting.text')}</p>
                            </div>

                            <img src={header} alt={t('general.accessibility.alt.laptop')}
                                 className="relative mt-16 w-fit h-auto mx-auto"/>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <Option title={t('services.pages.consulting.services.software.title')}
                    image={software}
                    imageAlt={t('services.pages.consulting.services.software.imageAlt')}
                    textLines={[
                        t('services.pages.consulting.services.software.text.1'),
                        t('services.pages.consulting.services.software.text.2'),
                        t('services.pages.consulting.services.software.text.3'),
                        t('services.pages.consulting.services.software.text.4')
                    ]}
                    orientation={"right"}
                    textClassName="dark:text-slate-100"
            />
            <Option title={t('services.pages.consulting.services.infrastructure.title')}
                    image={onSite}
                    imageAlt={t('services.pages.consulting.services.infrastructure.imageAlt')}
                    textLines={[
                        t('services.pages.consulting.services.infrastructure.text.1'),
                        t('services.pages.consulting.services.infrastructure.text.2'),
                        t('services.pages.consulting.services.infrastructure.text.3'),
                        t('services.pages.consulting.services.infrastructure.text.4')
                    ]}
                    orientation={"left"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <Option title={t('services.pages.consulting.services.ai.title')}
                    image={ai}
                    imageAlt={t('services.pages.consulting.services.ai.imageAlt')}
                    textLines={[
                        t('services.pages.consulting.services.ai.text.1'),
                        t('services.pages.consulting.services.ai.text.2'),
                        t('services.pages.consulting.services.ai.text.3'),
                        t('services.pages.consulting.services.ai.text.4')
                    ]}
                    orientation={"right"}
                    textClassName="dark:text-slate-100"
            />
            <Option title={t('services.pages.consulting.services.general.title')}
                    image={general}
                    imageAlt={t('services.pages.consulting.services.general.imageAlt')}
                    textLines={[
                        t('services.pages.consulting.services.general.text.1'),
                        t('services.pages.consulting.services.general.text.2')
                    ]}
                    orientation={"left"}
                    className="bg-gray-50 dark:bg-slate-800/50"
                    textClassName="text-slate-600"
            />
            <RelatedServices services={services} subtitle={t('services.relatedServices.subtitles.infrastructure')}
                             isLight/>
            <GetInTouch/>
            <Footer/>
        </>
    )
}
