import React, {useContext} from 'react'
import {Link} from "react-router-dom";
import Navbar from '../component/navbar';
import Footer from '../component/footer';
import {LanguageContext} from "../App";
import {useTranslation} from "react-i18next";
import {Cookies} from "react-cookie-consent";

export default function PrivacyPolicy() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');
    const [cookieConsent, setCookieConsent] = React.useState(Cookies.get('cookie-agreement') === 'true');

    const handleCookieAgreement = (e) => {
        setCookieConsent(!cookieConsent)
        Cookies.set('cookie-agreement', !cookieConsent, {path: '/'});
    }

    return (
        <>
            <div className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">

                <Navbar/>

                <section
                    className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-privacy.webp')] bg-cover bg-center bg-no-repeat"
                    style={{backgroundPosition: '0 32%'}}
                    id="home">
                    <div className="absolute inset-0 bg-slate-950/80"></div>

                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">{t('privacyPolicy.title')}</h3>
                        </div>
                    </div>
                </section>
                    <section className="relative md:py-12 py-16" id="privacy-policy">
                        {lang === 'pl' ? <div className="container mx-auto px-8">
                            <h4 className="h4 text-center mb-4">§1. Postanowienia Ogólne</h4>
                            <ol className="list-decimal text-justify mb-6">
                                <li>
                                    Administratorem danych jest Retoric sp. z o. o. z siedzibą w Katowicach (40-085),
                                    przy ul. Adama Mickiewicza 29, biuro podawcze: 213, wpisaną pod numerem KRS
                                    0001060555 do rejestru przedsiębiorców Krajowego Rejestru Sądowego. Ochrona danych
                                    odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich
                                    przechowywanie ma miejsce na zabezpieczonych serwerach.
                                </li>
                                <li>
                                    Dla interpretacji terminów stosuje się słowniczek Regulaminu lub tak jak zostało to
                                    opisane w Polityce Prywatności (jeżeli wynika to bezpośrednio z opisu).
                                </li>
                                <li>
                                    Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony
                                    został określeniem „Ty”, „Administrator” – „My”. Termin „RODO” oznacza
                                    Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
                                    r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
                                    sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.
                                </li>
                                <li>
                                    Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany
                                    jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL).
                                </li>
                                <li>
                                    Dane osobowe podawane w formularzach w obrębie witryny retoric.pl są traktowane jako
                                    poufne i nie
                                    są widoczne dla osób nieuprawnionych.
                                </li>
                            </ol>
                            <h4 className="h4 text-center mb-4">§2. Administrator Danych</h4>
                            <ol className="list-decimal text-justify mb-6">
                                <li>Usługodawca jest administratorem danych swoich klientów. Oznacza to, że jeśli
                                    posiadasz Konto na naszej stronie lub wysłałeś wiadomość poprzez formularz
                                    zaznaczając odpowiednie zgody na przetwarzanie danych, to przetwarzamy Twoje dane
                                    jak: imię, nazwisko, adres e-mail, numer telefonu, adres IP.
                                </li>
                                <li>Dane osobowe przetwarzane są:
                                    <ol className="list-decimal text-justify ml-6">
                                        <li>zgodnie z przepisami dotyczącymi ochrony danych osobowych,</li>
                                        <li>zgodnie z wdrożoną Polityką Prywatności,</li>
                                        <li>w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy,
                                            zmiany
                                            bądź jej rozwiązania oraz prawidłowej realizacji Usług świadczonych drogą
                                            elektroniczną.
                                        </li>
                                    </ol>
                                </li>
                                <li>Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo
                                    dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo
                                    sprzeciwu, prawo wniesienia skargi do organu nadzorczego.
                                </li>
                                <li>Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji Usługodawcy
                                    jest możliwy drogą elektroniczną pod adresem e-mail: <Link
                                        to="mailto:kontakt@retoric.pl">kontakt@retoric.pl</Link>.
                                </li>
                                <li>Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub
                                    cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed
                                    sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują
                                    nas do retencji danych.
                                </li>
                                <li>Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych
                                    podmiotom upoważnionym na podstawie właściwych przepisów prawa (np. organom
                                    ścigania).
                                </li>
                                <li>Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia
                                    prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.
                                </li>
                                <li>Usługodawca nie udostępniania danych osobowych innym podmiotom aniżeli upoważnionym
                                    na podstawie właściwych przepisów prawa.
                                </li>
                                <li>Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający,
                                    z którymi ściśle współpracujemy
                                </li>
                            </ol>
                            <h4 className="h4 text-center mb-4">§3. Pliki cookies</h4>
                            <ol className="list-decimal text-justify mb-6">
                                <li>Witryna retoric.pl używa cookies. Są to niewielkie pliki tekstowe wysyłane przez
                                    serwer www i przechowywane przez oprogramowanie komputera przeglądarki. Kiedy
                                    przeglądarka ponownie połączy się ze stroną, witryna rozpoznaje rodzaj urządzenia, z
                                    którego łączy się użytkownik. Parametry pozwalają na odczytanie informacji w nich
                                    zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają więc korzystanie z
                                    wcześniej odwiedzonych witryn. Gromadzone informacje dotyczą adresu IP, typu
                                    wykorzystywanej przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług
                                    internetowych, informacji o czasie i dacie, lokalizacji oraz informacji przesyłanych
                                    do witryny za pośrednictwem formularza kontaktowego.
                                </li>
                                <li>Zebrane dane służą do monitorowania i sprawdzenia, w jaki sposób użytkownicy
                                    korzystają z naszych witryn, aby usprawniać funkcjonowanie serwisu zapewniając
                                    bardziej efektywną i bezproblemową nawigację. Monitorowania informacji o
                                    użytkownikach dokonujemy korzystając z narzędzia Google Analitics, które rejestruje
                                    zachowanie użytkownika na stronie. Dodatkowo, wykorzystujemy narzędzie New Relic,
                                    które monitoruje zachowanie użytkowników na stronie oraz zbiera dane statystyczne
                                    odnośnie wydajności tej witryny. Cookies identyfikuje użytkownika, co pozwala na
                                    dopasowanie treści witryny, z której korzysta, do jego potrzeb. Zapamiętując jego
                                    preferencje, umożliwia odpowiednie dopasowanie skierowanych do niego reklam.
                                    Stosujemy pliki cookies, aby zagwarantować najwyższy standard wygody naszego
                                    serwisu, a zebrane dane są wykorzystywane jedynie wewnątrz firmy Retoric sp. z o.
                                    o. w celu optymalizacji działań.
                                </li>
                                <li>Na naszej witrynie wykorzystujemy następujące pliki cookies:
                                    <ol className="list-decimal text-justify ml-6">
                                        <li>„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w
                                            ramach serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług
                                            wymagających uwierzytelniania w ramach serwisu;
                                        </li>
                                        <li>pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do
                                            wykrywania nadużyć w zakresie uwierzytelniania w ramach serwisu;
                                        </li>
                                        <li>„wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie
                                            korzystania ze stron internetowych serwisu;
                                        </li>
                                        <li>„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez
                                            użytkownika ustawień i personalizację interfejsu użytkownika, np. w zakresie
                                            wybranego języka lub regionu, z którego pochodzi użytkownik, rozmiaru
                                            czcionki, wyglądu strony internetowej itp.;
                                        </li>
                                    </ol>
                                </li>
                                <li>Użytkownik w każdej chwili ma możliwość wyłączenia lub przywrócenia opcji
                                    gromadzenia cookies poprzez zmianę ustawień w przeglądarce internetowej.
                                    Instrukcja zarządzania plikami cookies jest dostępna na stronie
                                    &nbsp;
                                    <Link to={'http://www.allaboutcookies.org/manage-cookies'}
                                          target={'_blank'}>http://www.allaboutcookies.org/manage-cookies</Link>
                                </li>
                            </ol>
                        </div> : <div className="container mx-auto px-8">
                            <h4 className="h4 text-center mb-4">§1. General Provisions</h4>
                            <ol className="list-decimal text-justify mb-6">
                                <li>
                                    The data controller is Retoric sp. z o. o. with its registered office in Katowice
                                    (40-085), at ul. Adama Mickiewicza 29, delivery office: 213, which is a company in
                                    the process of organization (as of September 23, 2023). Data protection is carried
                                    out in accordance with the requirements of applicable law, and data storage takes
                                    place on secure servers.
                                </li>
                                <li>
                                    For the interpretation of terms, the glossary of the Regulations is used, or as
                                    described in the Privacy Policy (if it directly follows from the description).
                                </li>
                                <li>
                                    For a better understanding of the Privacy Policy, the term "User" has been
                                    replaced with "You," "Data controller" with "We." The term "GDPR" refers to the
                                    General Data Protection Regulation (GDPR), which is Regulation (EU) 2016/679 of the
                                    European Parliament and of the Council of 27 April 2016 on the protection of natural
                                    persons with regard to the processing of personal data and on the free movement of
                                    such data, and repealing Directive 95/46/EC.
                                </li>
                                <li>
                                    We respect the right to privacy and ensure data security. To achieve this, a secure
                                    communication encryption protocol (SSL) is used.
                                </li>
                                <li>
                                    Personal data provided using forms within retoric.pl website is treated as
                                    confidential and is not visible to unauthorized persons.
                                </li>
                            </ol>
                            <h4 className="h4 text-center mb-4">§2. Data Controller</h4>
                            <ol className="list-decimal text-justify mb-6">
                                <li>The service provider is the data controller of its customers' data. This means that
                                    if you have an account on our website or have sent a message through the form,
                                    indicating the appropriate consents for data processing, we process your data such
                                    as: name, surname, email address, phone number, IP address.
                                </li>
                                <li>Personal data is processed:
                                    <ol className="list-decimal text-justify ml-6">
                                        <li>in accordance with the provisions regarding the protection of personal
                                            data,
                                        </li>
                                        <li>in accordance with the implemented Privacy Policy,</li>
                                        <li>to the extent and for the purpose necessary to conclude, shape the content
                                            of the Agreement, change or terminate it, and properly perform the services
                                            provided electronically.
                                        </li>
                                    </ol>
                                </li>
                                <li>Each person whose data we process (if we are their controller) has the right to
                                    access the data, rectify, erase or restrict processing, the right to object, and the
                                    right to lodge a complaint with the supervisory authority.
                                </li>
                                <li>Contact with the person supervising the processing of personal data in the service
                                    provider's organization is possible by email at <Link
                                        to="mailto:contact@retoric.pl">contact@retoric.pl</Link>.
                                </li>
                                <li>We reserve the right to process your data after the Agreement has been terminated or
                                    consent has been withdrawn, but only to the extent necessary to pursue possible
                                    claims before a court or if national or EU regulations or international law obliges
                                    us to retain data.
                                </li>
                                <li>The service provider has the right to disclose the personal data of the User and
                                    other data to entities authorized under the relevant legal regulations (e.g., law
                                    enforcement authorities).
                                </li>
                                <li>Erasing personal data may occur as a result of withdrawing consent or raising a
                                    legally permissible objection to the processing of personal data.
                                </li>
                                <li>The service provider does not disclose personal data to entities other than those
                                    authorized under the relevant legal regulations.
                                </li>
                                <li>Personal data is processed only by persons authorized by us or by processors with
                                    whom we closely cooperate.
                                </li>
                            </ol>
                            <h4 className="h4 text-center mb-4">§3. Cookies</h4>
                            <ol className="list-decimal text-justify mb-6">
                                <li>The retoric.pl website uses cookies. These are small text files sent by the web
                                    server and stored by the browser software on the user's computer. When the browser
                                    reconnects to the site, the site recognizes the type of device from which the user
                                    connects. Parameters allow only the server that created them to read the information
                                    they contain. Cookies make it easier to use previously visited websites. The
                                    collected information includes IP address, type of browser used, language, operating
                                    system type, internet service provider, information about time and date, location,
                                    and information sent to the website via the contact form.
                                </li>
                                <li>The collected data is used to monitor and check how users use our websites in order
                                    to improve the functioning of the service, providing more efficient and trouble-free
                                    navigation. We monitor user behavior on the site using the Google Analytics tool,
                                    which records user behavior on the site. Furthermore, we use New Relic tools, which
                                    monitor user behavior on the site and collect statistics regarding performance of
                                    this website. Cookies identify the user, allowing the website content to be tailored
                                    to their needs. By remembering their preferences, it allows for the appropriate
                                    customization of ads directed to them. We use cookies to ensure the highest standard
                                    of convenience of our service, and the collected data is used only within the
                                    Retoric sp. z o. o. company for optimization purposes.
                                </li>
                                <li>On our website, we use the following types of cookies:
                                    <ol className="list-decimal text-justify ml-6">
                                        <li>"essential" cookies, allowing the use of services available within the
                                            service, e.g., authentication cookies used for services that require
                                            authentication within the service;
                                        </li>
                                        <li>security cookies, e.g., cookies used to detect abuses in the field of
                                            authentication within the service;
                                        </li>
                                        <li>"performance" cookies, allowing the collection of information on the use of
                                            the service's websites;
                                        </li>
                                        <li>"functional" cookies, allowing the user's settings and personalization of
                                            the user interface to be remembered, e.g., in terms of the selected language
                                            or region from which the user comes, font size, appearance of the website,
                                            etc.;
                                        </li>
                                    </ol>
                                </li>
                                <li>The user can disable or restore the option of collecting cookies at any time by
                                    changing the settings in their web browser. Instructions on how to manage cookies
                                    are available on the website <Link
                                        to={'http://www.allaboutcookies.org/manage-cookies'}
                                        target={'_blank'}>http://www.allaboutcookies.org/manage-cookies</Link>.
                                </li>
                            </ol>
                        </div>
                        }
                        <div className={'container mx-auto flex flex-row justify-center content-center'}>
                            <span className="mr-3 text-lg font-medium text-gray-900 dark:text-gray-300 h-6 inline-block">{t('cookieConsent.declineButton')}</span>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" checked={cookieConsent} className="sr-only peer" onChange={(e) => handleCookieAgreement(e)}/>
                                <div
                                    className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-brandColor-900 dark:peer-focus:ring-brandColor-900 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-brandColor-800"></div>
                            </label>
                            <span className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300 h-6 inline-block">{t('cookieConsent.all')}</span>
                        </div>
                    </section>
                    <div className="container lg:mt-24 mt-16">
                        <div className="grid grid-cols-1 text-center">
                            <h3 className="mb-6 md:text-2xl text-xl font-medium">{t('privacyPolicy.questions')}</h3>

                            <p className="text-slate-500 dark:text-slate-300 max-w-3xl mx-auto">{t('privacyPolicy.questionsText')}</p>

                            <div className="mt-6">
                                <Link to="/contactus"
                                      className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-brandColor-900 hover:bg-brandColor-800 border-brandColor-900 hover:brandColor-800 text-white rounded-md"><i
                                    className="uil uil-phone align-middle me-2"></i> {t('about.contactUs')}</Link>
                            </div>
                        </div>
                    </div>
                <Footer/>
            </div>
        </>
    )
}
