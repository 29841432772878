import React, {useContext, useEffect, useState} from 'react'
import {animateScroll as scroll} from 'react-scroll';
import {Link, useLocation} from "react-router-dom";
import {LanguageContext} from "../App";
import {CookieConsent} from "react-cookie-consent";
import {useTranslation} from "react-i18next";

const PageMap = {
    '/aboutus': {
        'en': 'About Us', 'pl': 'O nas',
    }, '/': {
        'en': 'Home', 'pl': 'Strona główna',
    }, '/services': {
        'en': 'Our services', 'pl': 'Zakres usług',
    }, '/services/intranet': {
        'en': 'Retoric Intranet', 'pl': 'Retoric Intranet',
    }, '/services/web-applications': {
        'en': 'Web & Desktop applications', 'pl': 'Aplikacje webowe i komputerowe',
    }, '/services/mobile-applications': {
        'en': 'Mobile applications', 'pl': 'Aplikacje mobilne',
    }, '/services/enterprise-it-support': {
        'en': 'Enterprise IT Support', 'pl': 'Wsparcie IT dla przedsiębiorstw',
    }, '/services/consulting': {
        'en': 'Consulting', 'pl': 'Doradztwo',
    }, '/services/artificial-intelligence': {
        'en': 'Artificial Intelligence', 'pl': 'Sztuczna Inteligencja',
    }, '/services/training': {
        'en': 'Training', 'pl': 'Szkolenia',
    }, '/services/infrastructure-management': {
        'en': 'Infrastructure management', 'pl': 'Zarządzanie infrastrukturą',
    }, '/services/audit': {
        'en': 'Audit', 'pl': 'Audyt',
    }, '/blogs': {
        'en': 'Blog', 'pl': 'Blog',
    }, '/careers': {
        'en': 'Careers', 'pl': 'Kariera w Retoric',
    }, '/privacy-policy': {
        'en': 'Privacy policy', 'pl': 'Polityka prywatności',
    }, '/contactus': {
        'en': 'Contact Us', 'pl': 'Kontakt',
    }
}

export default function Switcher() {
    // const [mode, setMode] = useState(localStorage.getItem('retoric-mode') || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light"));
    const [mode, setMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light");

    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');

    const location = useLocation();

    useEffect(() => {
        function onDarkModeChange(e) {
            setMode(e.matches ? "dark" : "light");
            localStorage.setItem('retoric-mode', e.matches ? "dark" : "light")
        }

        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', onDarkModeChange);

        if (mode === 'dark') {
            document.documentElement.className = 'dark';
        } else {
            document.documentElement.className = 'light';
        }

        if (lang === 'pl') {
            const title = PageMap[location.pathname] && PageMap[location.pathname][lang] ? `- ${PageMap[location.pathname][lang]}` : ``;

            document.title = `Retoric - Twoje oprogramowanie dla przedsiębiorstw ${title}`;
            document.querySelector('meta[name="description"]').setAttribute("content", "Kompleksowe rozwiązania programistyczne dla firm każdego rozmiaru. Jesteśmy gotowi sprostać Twoim wyzwaniom biznesowym.");
            document.querySelector('html').setAttribute("lang", "pl");
        } else {
            const title = PageMap[location.pathname] && PageMap[location.pathname][lang] ? `- ${PageMap[location.pathname][lang]}` : ``;

            document.title = `Retoric - Your choice for business software ${title}`;
            document.querySelector('meta[name="description"]').setAttribute("content", "Comprehensive programming services for businesses of all sizes. We are ready to meet your business challenges.");
            document.querySelector('html').setAttribute("lang", "en");
        }

        return (() => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', onDarkModeChange);
        });
    }, [mode, location, lang]);

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 500, smooth: true,
        });
    };

    function changeMode(mode, event) {
        switch (mode) {
            case 'mode':
                if (document.documentElement.className.includes("dark")) {
                    localStorage.setItem('retoric-mode', 'light');
                    setMode('light');
                } else {
                    localStorage.setItem('retoric-mode', 'dark');
                    setMode('dark');
                }
                break;
            default:
                break;
        }
    }

    return (<>
        {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
            <>
                <div className="fixed top-1/4 -right-2 z-3">
                <span className="relative inline-block rotate-90">
                    <input type="checkbox" className="checkbox opacity-0 absolute" id="chk"
                           defaultChecked={mode === 'light'} onClick={(event) => changeMode('mode', event)}/>
                    <label
                        className="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
                        htmlFor="chk">
                        <i className="uil uil-moon text-[20px] text-yellow-500"></i>
                        <i className="uil uil-sun text-[20px] text-yellow-500"></i>
                        <span
                            className="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7"></span>
                    </label>
                </span>
                </div>

                <span onClick={scrollToTop} id="back-to-top"
                      className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-violet-600 text-white justify-center items-center">
                <i className="uil uil-arrow-up"></i>
            </span>
                <CookieConsent
                    location="bottom"
                    buttonText={t('cookieConsent.acceptButton')}
                    declineButtonText={t('cookieConsent.declineButton')}
                    cookieName="cookie-agreement"
                    enableDeclineButton
                    containerClasses={'bg-slate-900/90 dark:bg-white/90 text-white dark:text-slate-900'}
                    declineButtonClasses={'bg-slate-600 hover:bg-slate-700 text-slate-100'}
                    buttonClasses={'bg-green-700 hover:bg-green-800 text-slate-100'}
                    ariaAcceptLabel={t('cookieConsent.acceptButton')}
                    ariaDeclineLabel={t('cookieConsent.declineButton')}
                    expires={150}
                >
                    {t('cookieConsent.text')}&nbsp;
                    <Link to="/privacy-policy" className={'text-brandColor-200 hover:text-brandColor-400 dark:text-brandColor-800 dark:hover:text-brandColor-600'}>
                        {t('cookieConsent.link')}
                    </Link>
                </CookieConsent>
            </> : <>
                <CookieConsent
                    location="bottom"
                    buttonText={t('cookieConsent.acceptButton')}
                    declineButtonText={t('cookieConsent.declineButton')}
                    cookieName="cookie-agreement"
                    enableDeclineButton
                    containerClasses={'bg-slate-900/90 dark:bg-white/90 text-white dark:text-slate-900'}
                    declineButtonClasses={'bg-red-700 hover:bg-red-800 text-slate-100'}
                    buttonClasses={'bg-green-700 hover:bg-green-800 text-slate-100'}
                    ariaAcceptLabel={t('cookieConsent.acceptButton')}
                    ariaDeclineLabel={t('cookieConsent.declineButton')}
                    expires={150}
                >
                    {t('cookieConsent.text')}&nbsp;
                    <Link to="/privacy-policy" className={'text-brandColor-200 hover:text-brandColor-400 dark:text-brandColor-800 dark:hover:text-brandColor-600'}>
                        {t('cookieConsent.link')}
                    </Link>
                </CookieConsent>
            </>}
    </>)
};
