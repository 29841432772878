import React, {useContext} from 'react'
import {Link} from "react-router-dom";
import {LanguageContext} from "../App";
import {useTranslation} from "react-i18next";
import {NewsData, ProcessBlogTitle} from "./blogs-news";

export default function BlogData() {
    const {lang} = useContext(LanguageContext);
    const [t] = useTranslation('common');

    return (
        <>
            <div className="container">
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">

                    {NewsData[lang].map((item, index) => (

                        <div
                            className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-gray-800 overflow-hidden flex flex-col"
                            key={index}>
                            <img src={item.image} alt={item.alt} className="w-fit h-auto"/>

                            <div className="content p-6 flex flex-col justify-between flex-1">
                                <Link to={`/blog/${ProcessBlogTitle(item.title)}`}
                                      className="text-lg hover:text-brandColor-600 dark:text-white dark:hover:text-brandColor-600 transition duration-500 ease-in-out font-medium h5">{item.title}</Link>
                                <p className="text-slate-500 dark:text-slate-300 mt-3 flex-1">{item.detail}</p>

                                <div className="mt-5">
                                    <Link to={`/blog/${ProcessBlogTitle(item.title)}`}
                                          className="hover:text-brandColor-600 dark:hover:text-brandColor-600 after:bg-brandColor-600 dark:text-white transition duration-500">{t('news.more')}<i
                                        className="uil uil-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
}
